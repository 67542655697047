import { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'
import { sortTableData } from '../shared/sort'
import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './ErrorFallbackUI'

/** A Compact Table with sorting capability that will span the width of its' container.
 * @param {Object[]} headers Array of header objects, determines sorting and text of the headers for each column
 * @param {boolean} headers[].sortable Determines whether or not the user can click the header to sort data
 * @param {string} [headers[].sortType] Determines the sorting method. Expected values: 'string', 'number', 'boolean', 'array', defaults to string. Optional if sortable is false.
 * @param {string} headers[].label Text that labels the column in the Table
 * @param {Object[]} tableData The array of data to be sorted and rendered in the table
 * @param {string} [tableData[].navpath] Optional path to navigate to when the row of table is clicked
 * @param {Object[]} tableData[].row Row in the table
 * @param {Function} [tableData[].row[].formatter] Optional formatter function that the table will apply to data
 * @param {*} tableData[].row[].data The actual data for the table cell
 */
const TableCompact = ({ headers, tableData }) => {
    // Sorted Array for Table
    const [sortedData, setSortedData] = useState(tableData ? tableData : [])
    // Selected sort column
    const [selectedColumn, setSelectedColumn] = useState(0)
    // Sorting Direction (asc, desc)
    const [sortDirection, setSortDirection] = useState('asc')
    // Navigation
    const nav = (path) => {
        if (path) {
            navigate(path)
        }
    }
    /**
     * Handler for when column header is clicked, determines sort
     * @param {number} index - index of the clicked header in the header array, determines sort column
     */
    const handleColumnClick = (index) => {
        // Determine if this column we clicked is already selected
        if (selectedColumn === index) {
            // It is, toggle sort direction between asc and desc
            sortDirection === 'asc'
                ? setSortDirection('desc')
                : setSortDirection('asc')
        } else {
            // It is not, set the sort direction to asc by default, and set our selected column
            setSelectedColumn(index)
            setSortDirection('asc')
        }
    }
    // Sort table whenever data, selected column, or sort direction changes
    useEffect(() => {
        // Do we even have data to sort?
        if (!tableData) return
        // Is our selected column sortable?
        if (!headers[selectedColumn].sortable) return
        // Yes, sort the data (Ascending by default)
        let sortArray = sortTableData(
            tableData,
            selectedColumn,
            headers[selectedColumn].sortType
        )
        // Descending (if specified)
        if (sortDirection === 'desc') {
            sortArray = sortArray.reverse()
        }
        // Update the state, fresh array to force render
        setSortedData([...sortArray])
    }, [headers, tableData, selectedColumn, sortDirection])
    return (
        <TableContainer
            style={{
                width: '100%',
                overflowX: 'auto',
                overflow: 'auto',
                maxHeight: '100%'
            }}
        >
            <ErrorBoundary fallback={<ErrorFallbackUI full={true} />}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow key="header-row">
                            {headers &&
                                headers.length > 0 &&
                                headers.map((header, index) => {
                                    return (
                                        <TableCell
                                            key={`${index}-${header.label}-header`}
                                        >
                                            {header.sortable ? (
                                                <TableSortLabel
                                                    active={
                                                        selectedColumn === index
                                                    }
                                                    direction={sortDirection}
                                                    onClick={() =>
                                                        handleColumnClick(index)
                                                    }
                                                >
                                                    {header.label}
                                                </TableSortLabel>
                                            ) : (
                                                header.label
                                            )}
                                        </TableCell>
                                    )
                                })}
                        </TableRow>
                    </TableHead>
                    <TableBody key="table-body">
                        {sortedData &&
                            sortedData.length > 0 &&
                            sortedData.map((data, index) => (
                                <TableRow
                                    key={`${index}-tablerow`}
                                    hover
                                    onClick={() => nav(data.navpath)}
                                >
                                    {data.row &&
                                        data.row.length > 0 &&
                                        data.row.map((cell, i) => {
                                            return (
                                                <TableCell
                                                    key={`${index}-row-${i}-cell`}
                                                >
                                                    {cell.formatter
                                                        ? cell.formatter(
                                                              cell.data
                                                          )
                                                        : cell.data}
                                                </TableCell>
                                            )
                                        })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </ErrorBoundary>
        </TableContainer>
    )
}

export default TableCompact

TableCompact.propTypes = {
    /** Array containing the header objects for each column */
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            sortable: PropTypes.bool,
            sortType: PropTypes.string,
            label: PropTypes.string
        })
    ),
    /** Array of objects, contains the navpath when a row is clicked, and a nested array containing the data cells */
    tableData: PropTypes.arrayOf(
        PropTypes.shape({
            navpath: PropTypes.string,
            row: PropTypes.arrayOf(
                PropTypes.shape({
                    formatter: PropTypes.func,
                    data: PropTypes.any
                })
            )
        })
    )
}
