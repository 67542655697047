import { useState, useContext, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ChangeAccountTypeModal from '../modals/ChangeAccountTypeModal'
import ChangeEmailModal from '../modals/ChangeEmailModal'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteUserModal from '../modals/DeleteUserModal'
import Divider from '@material-ui/core/Divider'
import EditUserServerAccessModal from '../modals/EditUserServerAccessModal'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { LoginContext } from '../contexts/LoginContext'
import { navigate } from '@reach/router'
import Page from '../components/Page'
import ResetPasswordModal from '../modals/ResetPasswordModal'
import SectionBar from '../components/SectionBar'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useModalState } from '../components/ModalWindow'
import { useQuery } from 'react-query'
import { useParams } from '@reach/router'
import { convertUNIXTime } from '../shared/formatting'
import reportError from '../shared/errors'

/** Page that provides the ability to manage the user, changing email, password, login scripts, etc. */
const User = ({ self }) => {
    // Style
    const buttonStyle = {
        minWidth: '165px'
    }
    // State / Context
    const { loginInfo, setUserData } = useContext(LoginContext)
    const theme = useTheme()
    // TODO IE: Modal Management
    const {
        isVisible: accountTypeIsVisible,
        openModal: openAccountTypeModal,
        closeModal: closeAccountTypeModal
    } = useModalState()
    const {
        isVisible: emailIsVisible,
        openModal: openEmailModal,
        closeModal: closeEmailModal
    } = useModalState()
    const {
        isVisible: serverAccessIsVisible,
        openModal: openServerAccessModal,
        closeModal: closeServerAccessModal
    } = useModalState()
    const {
        isVisible: deleteAccountIsVisible,
        openModal: openDeleteAccountModal,
        closeModal: closeDeleteAccountModal
    } = useModalState()
    const {
        isVisible: passwordModalIsVisible,
        openModal: openPasswordModal,
        closeModal: closePasswordModal
    } = useModalState()
    // Manage server access, whether to grant/revoke, and in case of revoke, which CID?
    const [serverAccess, setServerAccess] = useState({
        grant: false,
        cid: ''
    })
    // API Interaction
    const api = useAPIClient()
    // Get the Username from the URL. (It is OK if it is undefined)
    const params = useParams()
    let username = params.username
    // Get User Information
    const { data: user, refetch: getUser } = useQuery(
        // If we're using api.users.getSelf, then the username is ignored
        [{ qk: `getUser${username ? username : ''}`, username }],
        // We use different query if we're viewing our own page, rather than that of a separate user.
        self ? api.users.getSelf : () => api.users.getUser({ username }),
        {
            onSuccess: (userData) => {
                // Did we fetch data for our own user?
                if (!self) return // No, nothing to do here
                // Yes, update our loginInfo
                setUserData(
                    userData.data.displayname,
                    userData.data.admin,
                    userData.data.dealergroup
                )
            },
            onError: (error) => {
                // Report to Sentry
                reportError(error, 'getUser', username)
            }
        }
    )
    // If the logged-in user clicks their own name in User list, and they're
    // an account manager, we don't want them to be able to delete or demote
    // themselves, so we just navigate to their "self" user page in this case.
    useEffect(() => {
        if (loginInfo.username === username) {
            // Navigate to "Your Account"
            navigate('/')
        }
    })
    return (
        <Page>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SectionBar
                        text={self ? 'Your Information' : 'User Information'}
                    />
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Name:</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography>
                            <b> {user?.data.displayname}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider style={{ background: theme.palette.primary.dark }} />
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Username:</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography>
                            <b>{user?.data.username}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Account Type:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>
                                    {user?.data.admin
                                        ? 'Account Manager'
                                        : 'Standard User'}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                    {loginInfo.accountManager && !self && (
                        <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Grid item>
                                <Button
                                    color="default"
                                    variant="contained"
                                    size="small"
                                    style={buttonStyle}
                                    onClick={openAccountTypeModal}
                                >
                                    Edit Account Type
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Email Address:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>{user?.data.email}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Button
                                color="default"
                                variant="contained"
                                size="small"
                                style={buttonStyle}
                                onClick={openEmailModal}
                            >
                                Edit Email
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Password:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item xs>
                            <Button
                                color="secondary"
                                variant="contained"
                                style={buttonStyle}
                                onClick={openPasswordModal}
                            >
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SectionBar text="Login Information" />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Server Access:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid container item xs={12} spacing={0}>
                            {user?.data.dealerships.map((compcid) => {
                                return (
                                    <Grid
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        item
                                        xs={12}
                                        key={`userserveraccess${compcid}`}
                                    >
                                        <Grid item xs={6}>
                                            <Typography>
                                                <b>{compcid}</b>
                                            </Typography>
                                        </Grid>
                                        {loginInfo.accountManager && (
                                            <Grid item>
                                                <IconButton
                                                    onClick={() => {
                                                        setServerAccess({
                                                            grant: false,
                                                            cid: compcid.toString()
                                                        })
                                                        openServerAccessModal()
                                                    }}
                                                    size="small"
                                                >
                                                    <DeleteForeverIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    {loginInfo.accountManager && (
                        <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    style={buttonStyle}
                                    onClick={() => {
                                        setServerAccess({
                                            grant: true,
                                            cid: ''
                                        })
                                        openServerAccessModal()
                                    }}
                                >
                                    Add Server Access
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Last Good Login:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>
                                    {user?.data.lastlogon &&
                                        convertUNIXTime(user.data.lastlogon)}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Last Failed Login:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>
                                    {user?.data.badpasswordtime &&
                                        convertUNIXTime(
                                            user.data.badpasswordtime
                                        )}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Account Created:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>
                                    {user?.data.created &&
                                        convertUNIXTime(user.data.created)}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={3}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Last PW Reset:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>
                                    {user?.data.pwdlastset &&
                                        convertUNIXTime(user.data.pwdlastset)}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {loginInfo.accountManager && (
                    <>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <SectionBar text="Account Maintenance" />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            spacing={3}
                        >
                            <Grid
                                container
                                item
                                xs={4}
                                sm={4}
                                md={3}
                                lg={2}
                                justify="flex-end"
                            >
                                <Grid item>
                                    <Typography>Account Status:</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={3}
                                xl={2}
                            >
                                <Grid item>
                                    {user?.data.locked ? (
                                        <Typography color="error">
                                            <b>LOCKED</b>
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            <b>Unlocked</b>
                                        </Typography>
                                    )}
                                    {user?.data.disabled ? (
                                        <Typography color="error">
                                            <b>DISABLED</b>
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            <b>Enabled</b>
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {!self && (
                            <Grid container item xs spacing={2}>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={buttonStyle}
                                        onClick={openDeleteAccountModal}
                                    >
                                        Delete Account
                                    </Button>
                                </Grid>
                                {user?.data.locked && (
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={buttonStyle}
                                            onClick={openPasswordModal}
                                        >
                                            Unlock Account
                                        </Button>
                                    </Grid>
                                )}
                                {user?.data.disabled && (
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={buttonStyle}
                                            onClick={openPasswordModal}
                                        >
                                            Enable User
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>

            <ChangeAccountTypeModal
                isVisible={accountTypeIsVisible}
                closeModal={closeAccountTypeModal}
                username={user?.data.username}
                accountManager={!!user?.data.admin}
                refetch={getUser}
            />

            <EditUserServerAccessModal
                isVisible={serverAccessIsVisible}
                closeModal={closeServerAccessModal}
                username={user?.data.username}
                dealergroup={loginInfo.dealergroup}
                accessibleServers={user?.data.dealerships}
                grant={serverAccess.grant}
                revokeCID={serverAccess.cid}
                refetch={getUser}
            />

            <ChangeEmailModal
                isVisible={emailIsVisible}
                closeModal={closeEmailModal}
                username={user?.data.username}
                currentEmail={user?.data.email}
                refetch={getUser}
            />

            <DeleteUserModal
                isVisible={deleteAccountIsVisible}
                closeModal={closeDeleteAccountModal}
                username={username}
                navPath="/users/"
            />

            <ResetPasswordModal
                isVisible={passwordModalIsVisible}
                closeModal={closePasswordModal}
                username={username}
                email={user?.data.email}
                refetch={getUser}
                selfReset={self}
            />
        </Page>
    )
}

export default User

User.propTypes = {
    /** Whether or not the logged-in user is viewing their own page */
    self: PropTypes.bool
}
