import auth from './auth'
import dealergroups from './dealergroups'
import dealerships from './dealerships'
import users from './users'

// Gather all of our endpoints into an object for react-toolbox APIClient
const endpoints = {
    auth,
    dealergroups,
    dealerships,
    users
}

export default endpoints
