import { LoginProvider } from './contexts/LoginContext'
import ReactQueryClientProvider from './contexts/QueryClientContext'
import WrappedApp from './components/WrappedApp'
import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './components/ErrorFallbackUI'

function App() {
    return (
        <div className="App">
            <ErrorBoundary fallback={<ErrorFallbackUI full={true} />}>
                <LoginProvider>
                    <ReactQueryClientProvider>
                        <WrappedApp />
                    </ReactQueryClientProvider>
                </LoginProvider>
            </ErrorBoundary>
        </div>
    )
}

export default App
