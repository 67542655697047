import { useState, useContext, useEffect } from 'react'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { LoginContext } from '../contexts/LoginContext'
import { navigate } from '@reach/router'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useQuery } from 'react-query'
import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './ErrorFallbackUI'
import reportError from '../shared/errors'

/**
 * Side Navigation Menu that is included in the layout for all pages when user is signed in.
 */
const Sidebar = () => {
    // Menu Items differ depending on whether user is Account Manager or just a standard
    const standardUserMenuItems = [
        {
            text: 'Your Account',
            icon: <AccountBoxIcon color="secondary" />,
            path: '/',
            disabled: false
        }
    ]
    // State / Context
    const { loginInfo } = useContext(LoginContext)
    const [menuitems, setMenuItems] = useState(standardUserMenuItems)
    // API Interaction
    const api = useAPIClient()
    // Get Information for all the dealerships within dealergroup
    const { data: dg } = useQuery(
        ['getDealershipsInDealergroup', { dealergroup: loginInfo.dealergroup }],
        () =>
            api.dealergroups.getDealershipsInDealergroup({
                dealergroup: loginInfo.dealergroup
            }),
        {
            enabled: !!loginInfo.dealergroup,
            onError: (error) => {
                // Report to Sentry
                reportError(
                    error,
                    'getDealershipsInDealergroup',
                    loginInfo.username
                )
            }
        }
    )
    // Determine what menu items are available to user
    useEffect(() => {
        if (loginInfo.accountManager) {
            // Account Managers should be able to view all users and companies within their dealergroup
            let dealershipItems = []
            if (dg?.data) {
                dealershipItems = dg.data.map((dealership) => ({
                    text: dealership.name,
                    icon: <EmojiTransportationIcon color="secondary" />,
                    path: `/dealerships/${dealership.cid}`,
                    disabled: false
                }))
            }
            setMenuItems([
                ...standardUserMenuItems,
                {
                    text: 'All Users',
                    icon: <SupervisedUserCircleIcon color="secondary" />,
                    path: '/users/',
                    disabled: false
                },
                ...dealershipItems
            ])
            return
        }
        // Standard Users should only be able to view their account
        setMenuItems(standardUserMenuItems)

        // contrastStyle and standardUserMenuItems causes infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginInfo, dg])
    return (
        <ErrorBoundary fallback={<ErrorFallbackUI />}>
            <List>
                {menuitems.map((item) => (
                    <div key={item.text + 'div'}>
                        <ListItem
                            button
                            key={item.text}
                            disabled={item.disabled}
                            onClick={() => navigate(item.path)}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                        <Divider />
                    </div>
                ))}
            </List>
        </ErrorBoundary>
    )
}

export default Sidebar
