import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

/**
 * An extremely useful theme that propogates through all the Material UI Components.
 */
const hostedTheme = createMuiTheme({
    palette: {
        common: {
            black: '#212121',
            white: '#fff'
        },
        background: {
            paper: '#fff',
            default: '#fff'
        },
        primary: {
            light: '#212121',
            main: '#212121',
            dark: '#212121',
            contrastText: '#fff'
        },
        secondary: {
            light: '#2e3f88',
            main: '#fcc515',
            dark: '#ffe180',
            contrastText: '#212121'
        },
        error: {
            light: '#fcc515',
            main: '#f10',
            dark: '#fcc515',
            contrastText: '#fff'
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)'
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        },
        fontFamily: ['Montserrat']
    },
    props: {
        MuiTextField: {
            // Using White as Primary causes text fields to be invisible when clicked
            // The color prop only seems to take effect when the field is clicked or in focus
            // https://material-ui.com/api/text-field/
            color: 'secondary'
        }
    },
    overrides: {
        MuiTableCell: {
            // Header Text in Table Cells in Header Rows should always be bold, however it is not by default.
            // https://material-ui.com/api/table-cell/
            head: {
                fontWeight: 'bold'
            }
        }
    }
})

/**
 * Provider for the hostedTheme
 */
const HostedTheme = ({ children }) => {
    return <ThemeProvider theme={hostedTheme}>{children}</ThemeProvider>
}

export default HostedTheme

HostedTheme.propTypes = {
    children: PropTypes.node
}
