import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import Typography from '@material-ui/core/Typography'

const ErrorFallbackUI = ({ full = false }) => {
    return (
        <Grid container spacing={3}>
            <Grid container item xs={12} alignItems="center" justify="center">
                <Grid item>
                    <Typography variant="h5">Something went wrong!</Typography>
                </Grid>
            </Grid>
            {full && (
                <>
                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        alignItems="center"
                    >
                        <Grid item>
                            <SentimentVeryDissatisfiedIcon />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h6">
                                The action you were trying to take may or may
                                not have been successful. You can try to refresh
                                the page, verify if changes have been made, and
                                try again if necessary. If issues persist please
                                contact support at 888-963-5369
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

ErrorFallbackUI.propTypes = {
    /** Determines how much of the fallback UI is displayed */
    full: PropTypes.bool
}

export default ErrorFallbackUI
