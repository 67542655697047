import { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { ResultModal } from '../components/ModalWindow'
import PropTypes from 'prop-types'
import reportError from '../shared/errors'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useQuery, useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'
import { hostedPrices } from '../shared'

const EditUserServerAccessModal = ({
    isVisible,
    closeModal,
    username,
    dealergroup,
    grant,
    revokeCID,
    accessibleServers,
    refetch
}) => {
    // Array for allowing selection of only servers the user does not have access to already
    const [accessInfo, setAccessInfo] = useState([])
    // CID to grant access for
    const [grantCID, setGrantCID] = useState('')
    // Determine what is displayed
    const [modalState, setModalState] = useState({
        // main, success, failed
        display: 'main',
        message: ''
    })

    // Login context for username to be included in error reporting
    const { loginInfo } = useContext(LoginContext)
    // API Interaction
    const api = useAPIClient()
    // Get Companies within Dealergroup (Used to provide server access)
    const { refetch: getDealerships } = useQuery(
        ['getDealershipsInDealergroup', { dealergroup: dealergroup }],
        () => api.dealergroups.getDealershipsInDealergroup({ dealergroup }),
        {
            enabled: !!dealergroup,
            onSuccess: (dealerships) => {
                // Populate a list of companies the user does NOT have access to in the dealergroup.
                let ds = dealerships?.data?.filter(
                    (i) => !accessibleServers?.includes(i.cid)
                )
                // the filter returns undefined if no results, rather than empty array
                if (ds === undefined) {
                    ds = []
                }
                // Sort array https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
                ds.sort((a, b) => (a.cid > b.cid ? 1 : -1))
                setAccessInfo(ds)
            },
            onError: (error) => {
                // Display failure and report error to Sentry
                reportError(
                    error,
                    'getDealershipsInDealergroup',
                    loginInfo.username
                )
                setModalState({
                    display: 'failed',
                    message:
                        'Unable to fetch available servers in dealergroup to determine access. Please try again.'
                })
            }
        }
    )

    // Query to get dealership info to check if adding a user will raise server tier.
    const { data: { data: dealership } = {} } = useQuery(
        ['getUsersInCompany', { cid: grantCID }],
        () => api.dealerships.getDealershipInfo({ cid: grantCID }),
        {
            enabled: !!grantCID,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: ({ data: dealership }) => {
                if (
                    !!hostedPrices[dealership?.users.length + 1] &&
                    dealership?.users.length > 0
                ) {
                    // They are going to exceed a threshhold. Show a warning when they go to grant access
                    setShowWarning(true)
                } else {
                    // the user may have toggled from a dealership that should show a warning, so fix that
                    setShowWarning(false)
                }
            },
            onError: (error) => {
                // No modal popup or message necessary if this fails.
                // Report to Sentry
                reportError(error, 'getDealershipInfo', loginInfo.username)
            }
        }
    )

    // Whether the tier modal is currently visible
    const [showTierModal, setShowTierModal] = useState(false)
    // Whether we've got backing data that requires showing the tier modal
    const [showWarning, setShowWarning] = useState(false)

    // Mutations
    const {
        mutate: grantAccessToServer,
        isLoading: grantIsLoading
    } = useMutation(api.users.addUserToSecurityGroup)
    const {
        mutate: revokeAccessToServer,
        isLoading: revokeIsLoading
    } = useMutation(api.users.removeUserFromSecurityGroup)
    // Mutation Handlers
    const grantAccessToServerHandler = () => {
        // Prevent triggering multiple API calls
        if (grantIsLoading) {
            return
        }
        // Fire request
        grantAccessToServer(
            {
                qk: 'grantAccessToServer',
                username: username,
                cid: grantCID
            },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `User: ${username} now has access to CID ${grantCID}.`
                    })
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(
                        error,
                        'grantAccessToServer',
                        loginInfo.username
                    )
                    setModalState({
                        display: 'failed',
                        message: `Failed to grant ${username} access to CID ${grantCID}. Please try again.`
                    })
                }
            }
        )
    }
    const revokeAccessToServerHandler = () => {
        // Prevent triggering multiple API calls
        if (revokeIsLoading) {
            return
        }
        // Fire request
        revokeAccessToServer(
            {
                qk: 'revokeAccessToServer',
                username: username,
                cid: revokeCID
            },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `User: ${username} no longer has access to CID ${revokeCID}.`
                    })
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(
                        error,
                        'revokeAccessToServer',
                        loginInfo.username
                    )
                    setModalState({
                        display: 'failed',
                        message: `Failed to revoke ${username} access to CID ${revokeCID}. Please try again.`
                    })
                }
            }
        )
    }

    // Reset state whenever isVisible is toggled
    useEffect(() => {
        setModalState({ display: 'main', message: '' })
        setAccessInfo([])
        setGrantCID('')
        if (isVisible) {
            getDealerships()
        }
        // It wants getDealerships included as dependency, that is undesired as it could cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])
    return (
        <>
            {modalState.display !== 'success' &&
                modalState.display !== 'failed' && (
                    <ModalWindow
                        open={isVisible}
                        // Dual purpose modal, content and actions depend on accessInfo state
                        actionApply={() => {
                            if (grant) {
                                if (showWarning) {
                                    setShowTierModal(true)
                                    return
                                }
                                grantAccessToServerHandler()
                            } else {
                                revokeAccessToServerHandler()
                            }
                        }}
                        // We want the action disabled for several cases:
                        // If grant is true
                        // - If CID field is empty
                        // - If user already has access to all dealerships in the dealergroup
                        // - If grant mutation isLoading
                        // If grant is false
                        // - If no CID passed in via props
                        // - If revoke mutation isLoading
                        actionApplyDisabled={
                            grant
                                ? grantCID === '' ||
                                  accessInfo.length === 0 ||
                                  grantIsLoading
                                : !revokeCID || revokeIsLoading
                        }
                        // Determine action (grant/revoke), then determine appropriate action text (send/sending)
                        textApply={
                            grant
                                ? grantIsLoading
                                    ? 'Granting Access...'
                                    : 'Grant Access'
                                : revokeIsLoading
                                ? 'Revoking Access...'
                                : 'Revoke Access'
                        }
                        actionCancel={closeModal}
                        title="Edit Server Access"
                        maxWidth="xs"
                    >
                        <Grid container alignItems="center" spacing={3}>
                            {grant ? (
                                <Grid container item xs={12}>
                                    {accessInfo.length > 0 ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Which Company do you want
                                                    this user to have access to?
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    size="small"
                                                    variant="outlined"
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    fullWidth
                                                    value={grantCID}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        // Not a real way to set the name field from a simple option value
                                                        // But in this case we don't need to, it will be set when needed.
                                                        setGrantCID(
                                                            e.target.value
                                                        )
                                                    }}
                                                >
                                                    <option
                                                        key={`serveraccesspickone`}
                                                        value={''}
                                                    >
                                                        (Select an option)
                                                    </option>
                                                    {accessInfo.map((comp) => {
                                                        return (
                                                            <option
                                                                key={`serveraccess${comp.cid}`}
                                                                value={comp.cid}
                                                            >
                                                                {`${comp.cid} ${comp.name}`}
                                                            </option>
                                                        )
                                                    })}
                                                </TextField>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography>
                                                User already has access to all
                                                available Companies within the
                                                Dealergroup.
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Are you sure you wish to remove
                                            access from {revokeCID}?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <ModalWindow
                            open={showTierModal}
                            actionApply={() => {
                                setShowTierModal(false)
                                // also do the granted workflow
                                grantAccessToServerHandler()
                            }}
                            actionCancel={() => setShowTierModal(false)}
                        >
                            Adding this user to {dealership?.name} will increase
                            your dealership tier to level{' '}
                            {hostedPrices[dealership?.users.length + 1]?.tier}.
                            This will raise your price to $
                            {hostedPrices[dealership?.users.length + 1]?.price} per month.
                            Would you like to continue?
                        </ModalWindow>
                    </ModalWindow>
                )}
            {modalState.display === 'success' && (
                <ResultModal
                    open={isVisible}
                    title="Success"
                    actionCancel={closeModal}
                    iconType="success"
                    message={modalState.message}
                    copyButton
                />
            )}
            {modalState.display === 'failed' && (
                <ResultModal
                    open={isVisible}
                    title="Error"
                    actionCancel={closeModal}
                    iconType="error"
                    message={modalState.message}
                />
            )}
        </>
    )
}

export default EditUserServerAccessModal

EditUserServerAccessModal.propTypes = {
    /** Determines whether or not to show the modal */
    isVisible: PropTypes.bool.isRequired,
    /** Function to set isVisible to false. Usually from the useModalState hook */
    closeModal: PropTypes.func.isRequired,
    /** Username of the user to grant/revoke server access */
    username: PropTypes.string,
    /** Dealergroup the user belongs to, for populating available dealerships to access */
    dealergroup: PropTypes.string,
    /** Whether to grant or revoke server access */
    grant: PropTypes.bool,
    /** If grant is false, then a CID of the server to revoke access to should be provided */
    revokeCID: PropTypes.string,
    /** Array of CIDs that the user already has access to, for purposes of filtering without a duplicate API call */
    accessibleServers: PropTypes.array,
    /** Function to refetch fresh data after a successful mutation */
    refetch: PropTypes.func.isRequired
}
