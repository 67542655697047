import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './ErrorFallbackUI'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

// Wrapper for every page
const Page = ({ children }) => {
    // Theming
    const theme = useTheme()
    return (
        <main style={{ padding: theme.spacing(3) }}>
            <ErrorBoundary fallback={<ErrorFallbackUI full={true} />}>
                {children}
            </ErrorBoundary>
        </main>
    )
}

export default Page

Page.propTypes = {
    children: PropTypes.node
}
