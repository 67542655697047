import { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Page from '../components/Page'
import SectionBar from '../components/SectionBar'
import TableCompact from '../components/TableCompact'
import Typography from '@material-ui/core/Typography'
import { LoginContext } from '../contexts/LoginContext'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useQuery } from 'react-query'
import { convertUNIXTime } from '../shared/formatting'
import { useTheme } from '@material-ui/core/styles'
import reportError from '../shared/errors'

// Table Headers
const headers = [
    {
        sortable: true,
        sortType: 'string',
        label: 'Name'
    },
    {
        sortable: true,
        sortType: 'string',
        label: 'Username'
    },
    {
        sortable: true,
        sortType: 'string',
        label: 'Email'
    },
    {
        sortable: true,
        sortType: 'boolean',
        label: 'Account Manager'
    },
    {
        sortable: true,
        sortType: 'array',
        label: 'Server Access'
    },
    {
        sortable: true,
        sortType: 'number',
        label: 'Last Login'
    }
]

const AllUsers = () => {
    const theme = useTheme()
    // We need loginInfo for the logged-in user's dealergroup
    const { loginInfo } = useContext(LoginContext)
    // tableData to feed into TableCompact
    const [tableData, setTableData] = useState([])
    // API Interaction
    const api = useAPIClient()
    // Get User Information
    useQuery(
        ['getUsersInDealergroup', { dealergroup: loginInfo.dealergroup }],
        () =>
            api.dealergroups.getUsersInDealergroup({
                dealergroup: loginInfo.dealergroup
            }),
        {
            onSuccess: (users) => {
                if (users?.data && Array.isArray(users.data)) {
                    prepareTableData(users.data)
                }
            },
            onError: (error) => {
                // Report to Sentry
                reportError(error, 'getUsersInDealergroup', loginInfo.username)
            }
        }
    )
    /** Helper function to prepare table data for TableCompact component */
    const prepareTableData = (users) => {
        // Prepare tableData
        let td = users.map((user) => ({
            navpath: `/users/${user.username}`,
            row: [
                { data: user.displayname },
                { data: user.username },
                { data: user.email },
                { data: user.admin, formatter: (val) => (val ? 'Yes' : '') },
                { data: user.dealerships, formatter: (val) => val.join(', ') },
                {
                    data: user.lastlogon,
                    formatter: (val) => convertUNIXTime(val)
                }
            ]
        }))
        // Update the state
        setTableData(td)
    }
    return (
        <Page>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid container item xs={12}>
                    <SectionBar text={`All Users ${
                        tableData.length !== 0 ? 
                        `(${tableData.length})` : ""
                    }`} />
                </Grid>
                <Grid container item xs={12}>
                    <Typography style={{ paddingLeft: theme.spacing(1) }}>
                        Listed below are all the Hosted Users within your
                        Dealergroup. Click any user to view more details.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableCompact headers={headers} tableData={tableData} />
                </Grid>
            </Grid>
        </Page>
    )
}

export default AllUsers
