import { useState, useEffect, useContext } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { ResultModal } from '../components/ModalWindow'
import PropTypes from 'prop-types'
import reportError from '../shared/errors'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { navigate } from '@reach/router'
import { useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'

const DeleteUserModal = ({ isVisible, closeModal, username, navPath }) => {
    // Text field state for deletion confirmation
    const [fieldtext, setFieldtext] = useState('')
    // Disable action until they type in the username of the account to delete
    const [disabled, setDisabled] = useState(true)
    // Visual feedback of if fieldtext matches username
    const [icon, setIcon] = useState(<ErrorIcon />)
    // Determine what is displayed
    const [modalState, setModalState] = useState({
        // main, success, failed
        display: 'main',
        message: ''
    })
    // Login context for username to be included in error reporting
    const { loginInfo } = useContext(LoginContext)
    // API Interaction
    const api = useAPIClient()
    // Mutation
    const { mutate: deleteUser, isLoading } = useMutation(api.users.deleteUser)
    // Mutation Handler
    const deleteUserHandler = () => {
        // Prevent triggering multiple API calls
        if (isLoading) {
            return
        }
        // Fire request
        deleteUser(
            { qk: 'deleteUser', username: username },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `User: ${username} has been deleted successfully`
                    })
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'deleteUserHandler', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message: `Failed to delete the user ${username}. Please try again.`
                    })
                }
            }
        )
    }
    // Ensure the field matches the name before allowing the user to click Delete (Also changes icon)
    useEffect(() => {
        if (fieldtext === username) {
            setDisabled(false)
            setIcon(<CheckCircleIcon />)
        } else {
            setDisabled(true)
            setIcon(<ErrorIcon />)
        }
    }, [fieldtext, username])
    // Reset state whenever isVisible is toggled
    useEffect(() => {
        setModalState({ display: 'main', message: '' })
        setFieldtext('')
    }, [isVisible])
    return (
        <>
            {modalState.display !== 'success' &&
                modalState.display !== 'failed' && (
                    <ModalWindow
                        open={isVisible}
                        title="Delete Account"
                        actionApply={deleteUserHandler}
                        actionCancel={closeModal}
                        textApply="Delete"
                        actionApplyDisabled={disabled || isLoading}
                        maxWidth="sm"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    Are you sure you wish to delete{' '}
                                    <strong>{username}</strong>?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    To confirm, type <strong>{username}</strong>{' '}
                                    in the field below to delete this account.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {icon}
                                            </InputAdornment>
                                        )
                                    }}
                                    label="Target Username"
                                    onChange={(e) =>
                                        setFieldtext(e.target.value)
                                    }
                                    value={fieldtext}
                                    fullWidth
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                            // Trigger user deletion if action is enabled when user presses Enter
                                            if (!disabled && !isLoading) {
                                                deleteUserHandler()
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ModalWindow>
                )}
            {modalState.display === 'success' && (
                <ResultModal
                    open={isVisible}
                    title="Success"
                    actionCancel={() => {
                        closeModal()
                        navigate(navPath)
                    }}
                    iconType="success"
                    message={modalState.message}
                    copyButton
                />
            )}
            {modalState.display === 'failed' && (
                <ResultModal
                    open={isVisible}
                    title="Error"
                    actionCancel={closeModal}
                    iconType="error"
                    message={modalState.message}
                />
            )}
        </>
    )
}

export default DeleteUserModal

DeleteUserModal.propTypes = {
    /** Determines whether or not to show the modal */
    isVisible: PropTypes.bool.isRequired,
    /** Function to set isVisible to false. Usually from the useModalState hook */
    closeModal: PropTypes.func.isRequired,
    /** Username of the user account to delete */
    username: PropTypes.string,
    /** Path of the page to navigate to after successful deletion (differs from dealership or third party user) */
    navPath: PropTypes.string
}
