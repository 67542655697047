import { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { ResultModal } from '../components/ModalWindow'
import PropTypes from 'prop-types'
import reportError from '../shared/errors'
import { checkPassword } from '../shared/formatting'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'

const ResetPasswordModal = ({
    isVisible,
    closeModal,
    username,
    email,
    selfReset,
    refetch
}) => {
    // Allow the user to set a custom password if desired, as well as show/hide
    const [newPassword, setNewPassword] = useState('')
    const [passwordInvalidMessage, setPasswordInvalidMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [reauthFailed, setReauthFailed] = useState(false)
    // Determine what is displayed
    const [modalState, setModalState] = useState({
        // main, success, failed
        display: 'main',
        message: ''
    })
    // Login context for username to be included in error reporting
    const { loginInfo, clearLoginInfo, initializeLoginInfo } = useContext(
        LoginContext
    )
    // API Interaction
    const api = useAPIClient()
    // Mutations
    const {
        mutate: changePassword,
        isLoading: passwordIsLoading
    } = useMutation(
        selfReset ? api.users.setPasswordSelf : api.users.setPassword
    )
    const { mutate: enableUser, isLoading: enableIsLoading } = useMutation(
        api.users.enableUser
    )
    const { mutate: unlockUser, isLoading: unlockIsLoading } = useMutation(
        api.users.unlockUser
    )
    const { mutate: login, isLoading: loginIsLoading } = useMutation(
        api.auth.login
    )
    // Mutation Handlers
    // When resetting password for another user, we accomplish this in 3 parts:
    // 1. Change the password (sets the password and sends the credentials email)
    // 2. Enable the user account (succeeds even if already enabled)
    // 3. Unlock the user account (succeeds even if already enabled)
    // When resetting your own password, we follow different steps:
    // 1. Change the password (sets password, credentials email is only sent if a password is generated by API)
    // 2. Reauthenticate (differs depending on custom or generated password)
    //    a. If custom password, attempt to sign in and update loginInfo
    //    b. If generated password, bring user back to login screen
    const changePasswordHandler = () => {
        // 1. Change the password
        // Prevent triggering multiple API calls
        if (
            passwordIsLoading ||
            enableIsLoading ||
            unlockIsLoading ||
            loginIsLoading
        ) {
            return
        }
        // Ensure password is at least 10 characters long (if custom password)
        if (newPassword.length !== 0 && newPassword.length < 10) {
            setPasswordInvalidMessage(
                'Password must be at least 10 characters in length'
            )
            return
        }
        // Fire request
        changePassword(
            {
                qk: 'changePassword',
                username: username, // unused if selfReset
                password: newPassword
            },
            {
                onSuccess: () => {
                    // If we're resetting our own password, try to reauthenticate
                    if (selfReset) {
                        if (newPassword.length > 0) {
                            // We're using a custom password, try to reauthenticate with it
                            loginHandler()
                            return
                        }
                        // A password was generated for us, we'll need that from the email. Go to login page
                        setReauthFailed(true)
                        setModalState({
                            display: 'success',
                            message: `Your Password Reset was successful, and credentials have been sent to ${email}. You will be brought back to the login page to sign in with your new password.`
                        })
                        return
                    }
                    // Otherwise move onto step 2
                    enableUserHandler()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'changePassword', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message:
                            'Failed to apply Password changes. Please try again.'
                    })
                }
            }
        )
    }
    const enableUserHandler = () => {
        // 2. Enable the user account
        // Fire request
        enableUser(
            { qk: 'enableUser', username: username },
            {
                onSuccess: () => {
                    // Move onto step 3
                    unlockUserHandler()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'enableUser', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message:
                            'Password reset, and credentials email sent, however failed to auto-enable and auto-unlock user. Verify their Account Status is Unlocked and Enabled.'
                    })
                }
            }
        )
    }
    const unlockUserHandler = () => {
        // 3. Unlock the user account
        // Fire request
        unlockUser(
            { qk: 'unlockUser', username: username },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `Password Reset for User: ${username} was successful, credentials email sent, and the user has been successfully enabled and unlocked.`
                    })
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'unlockUser', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message:
                            'Password reset, and credentials email sent, auto-enabled user, however failed to auto-unlock user. Verify their Account Status is Unlocked and Enabled.'
                    })
                }
            }
        )
    }
    const loginHandler = () => {
        // Reathenticate session
        // If doing a self reset, we'll need to retrieve username from session
        if (selfReset) {
            username = loginInfo.username
        }
        // Fire request
        login(
            { qk: 'login', username, password: newPassword },
            {
                onSuccess: (data) => {
                    if (data.error) {
                        reportError(data.error, 'login', username)
                        setReauthFailed(true)
                        setModalState({
                            display: 'success',
                            message:
                                'Password reset successfully, however failed to reauthenticate your current session. You will be brought back to the login page to sign in with your new password.'
                        })
                        return
                    }
                    // Reauthenticate our session
                    initializeLoginInfo(data.data.token, username)
                    setModalState({
                        display: 'success',
                        message: 'Password reset successfully.'
                    })
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'login', username)
                    setReauthFailed(true)
                    setModalState({
                        display: 'success',
                        message:
                            'Password reset successfully, however failed to reauthenticate your current session. You will be brought back to the login page to sign in with your new password.'
                    })
                }
            }
        )
    }
    // Check if password is valid as the user enters one
    useEffect(() => {
        // Don't bother checking if the user hasn't entered anything
        if (newPassword === '') {
            return
        }
        if (checkPassword(newPassword)) {
            // Password is good, contains no bad characters
            setPasswordInvalidMessage('')
        } else {
            // Invalid character detected, inform user
            setPasswordInvalidMessage(
                "Allowed characters: a-z, A-Z, 0-9, $&+,:;=?@#|'.^*%!_-"
            )
        }
    }, [newPassword])
    // Reset state whenever isVisible is toggled
    useEffect(() => {
        setModalState({ display: 'main', message: '' })
        setNewPassword('')
        setPasswordInvalidMessage('')
        setReauthFailed(false)
        setShowPassword(false)
    }, [isVisible])
    return (
        <>
            {modalState.display !== 'success' &&
                modalState.display !== 'failed' && (
                    <ModalWindow
                        open={isVisible}
                        title="Reset Password"
                        actionApply={changePasswordHandler}
                        actionApplyDisabled={
                            passwordIsLoading ||
                            enableIsLoading ||
                            unlockIsLoading ||
                            loginIsLoading ||
                            passwordInvalidMessage !== ''
                        }
                        textApply={
                            passwordIsLoading ||
                            enableIsLoading ||
                            unlockIsLoading ||
                            loginIsLoading
                                ? 'Resetting Password...'
                                : 'Reset Password'
                        }
                        actionCancel={closeModal}
                        maxWidth="sm"
                    >
                        {selfReset ? (
                            <Typography>
                                Are you sure you'd like to reset your password?
                            </Typography>
                        ) : (
                            <>
                                <Typography>
                                    Are you sure you'd like to reset the
                                    password for <strong>{username}</strong>?
                                    <br />
                                    <br />
                                </Typography>
                                <Typography>
                                    Credentials will be generated and sent to
                                    the email below.
                                    <br />
                                    <br />
                                </Typography>
                                <Typography>
                                    Performing a Password Reset also ensures the
                                    User is unlocked and enabled.
                                </Typography>
                            </>
                        )}
                        <br />
                        <Grid container spacing={2}>
                            {selfReset ? (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid
                                        container
                                        item
                                        justify="flex-end"
                                        xs={3}
                                    >
                                        <Grid item>
                                            <Typography>
                                                New Password:
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label="Password"
                                            autoComplete="new-password"
                                            value={newPassword}
                                            helperText={
                                                passwordInvalidMessage ||
                                                'Leave blank to have a random one generated for you.'
                                            }
                                            error={
                                                passwordInvalidMessage !== ''
                                            }
                                            fullWidth
                                            onChange={(e) =>
                                                setNewPassword(e.target.value)
                                            }
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            onClick={() =>
                                                                setShowPassword(
                                                                    !showPassword
                                                                )
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid
                                            container
                                            item
                                            justify="flex-end"
                                            xs={3}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography>
                                                    Username:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                <strong>{username}</strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid
                                            container
                                            item
                                            justify="flex-end"
                                            xs={3}
                                        >
                                            <Grid item>
                                                <Typography>Email:</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography noWrap>
                                                <strong>{email}</strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </ModalWindow>
                )}
            {modalState.display === 'success' && (
                <ResultModal
                    open={isVisible}
                    title="Success"
                    actionCancel={() => {
                        if (reauthFailed) {
                            clearLoginInfo(
                                'Password reset successful, please sign in with your new Password.'
                            )
                        }
                        closeModal()
                    }}
                    iconType="success"
                    message={modalState.message}
                    copyButton
                />
            )}
            {modalState.display === 'failed' && (
                <ResultModal
                    open={isVisible}
                    title="Error"
                    actionCancel={closeModal}
                    iconType="error"
                    message={modalState.message}
                />
            )}
        </>
    )
}

export default ResetPasswordModal

ResetPasswordModal.propTypes = {
    /** Determines whether or not to show the modal */
    isVisible: PropTypes.bool.isRequired,
    /** Function to set isVisible to false. Usually from the useModalState hook */
    closeModal: PropTypes.func.isRequired,
    /** Username of the user to reset the password for */
    username: PropTypes.string,
    /** Email of the user to reset the password for */
    email: PropTypes.string,
    /** If the user is resetting their own password, we can't unlock and enable the account */
    selfReset: PropTypes.bool,
    /** Function to refetch fresh data after a successful mutation */
    refetch: PropTypes.func.isRequired
}
