import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// Initialize the Sentry React SDK as soon as possible during application load up, before initializing React
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [process.env.REACT_APP_SENTRY_ORIGIN]
        })
    ],
    tracesSampleRate: 1.0
})

// if we're in development, initialize the service worker
if (process.env.NODE_ENV === 'development') {
    require('./mocks/browser')
}

ReactDOM.render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
