import { useState } from 'react'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './ErrorFallbackUI'

/** ModalWindow is much of boilerplate code for a modal, can be used directly to wrap content. Used in other custom modals defined here as well. The 'open' prop should be managed with useModalState hook. */
const ModalWindow = ({
    children,
    open,
    title,
    actionApply,
    actionCancel,
    textCancel,
    textApply,
    maxWidth,
    actionApplyDisabled = false
}) => {
    if (!open) return null
    return (
        <Dialog open={true} fullWidth maxWidth={maxWidth ? maxWidth : 'xs'}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <ErrorBoundary fallback={<ErrorFallbackUI full={true} />}>
                    {children}
                </ErrorBoundary>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => actionCancel()}>
                    {textCancel ? textCancel : 'Cancel'}
                </Button>
                {actionApply && (
                    <Button
                        onClick={() => actionApply()}
                        disabled={actionApplyDisabled}
                    >
                        {textApply ? textApply : 'Apply'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

ModalWindow.propTypes = {
    children: PropTypes.node,
    /** Whether or not the modal is shown */
    open: PropTypes.bool.isRequired,
    /** Title Text */
    title: PropTypes.string,
    /** Function for closing modal and applying changes */
    actionApply: PropTypes.func,
    /** Function for closing modal without applying changes */
    actionCancel: PropTypes.func.isRequired,
    /** Text of the Apply button (Defaults to "Apply") */
    textApply: PropTypes.string,
    /** Text of the Cancel button (Defaults to "Cancel") */
    textCancel: PropTypes.string,
    /** Sets the Max Width to the max value of a specified breakpoint ("xs","sm","md","lg","xl") Defaults to "xs" */
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    /** Useful for if a condition is required before enabling "Apply" (Defaults to false) */
    actionApplyDisabled: PropTypes.bool
}

export default ModalWindow

/** A convenient modal with a single button to detail the result of an action to a user. The 'open' prop should be managed with useModalState hook. */
export const ResultModal = ({
    open,
    title,
    actionCancel,
    textCancel = 'Close',
    iconType = 'info',
    message
}) => {
    const iconSize = { height: 128, width: 128 }
    const icons = {
        question: <HelpOutlineIcon color="secondary" style={iconSize} />,
        warning: <ReportProblemOutlinedIcon color="error" style={iconSize} />,
        error: <ErrorOutlineIcon color="error" style={iconSize} />,
        success: <CheckCircleOutlineIcon color="secondary" style={iconSize} />,
        info: <InfoOutlinedIcon color="secondary" style={iconSize} />
    }
    if (!open) return null
    return (
        <ModalWindow
            open={true}
            title={title}
            singleButton={true}
            actionCancel={actionCancel}
            textCancel={textCancel}
        >
            <Grid container>
                <Grid container justify="center" item xs={12}>
                    <Grid item>{icons[iconType]}</Grid>
                </Grid>
                <Grid container justify="center" item xs={12}>
                    <Grid item>
                        <Typography>
                            <b>{message}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </ModalWindow>
    )
}

ResultModal.propTypes = {
    /** Whether or not modal is shown */
    open: PropTypes.bool.isRequired,
    /** Title in the Modal Header */
    title: PropTypes.string,
    /** Function to close the modal */
    actionCancel: PropTypes.func.isRequired,
    /** Text of the Cancel button, defaults to "Close" */
    textCancel: PropTypes.string,
    /** One of question, warning, error, success, info, defaults to info */
    iconType: PropTypes.oneOf([
        'question',
        'warning',
        'error',
        'success',
        'info'
    ]),
    /** String of text displayed to user, the purpose of the modal. */
    message: PropTypes.string
}

/** Hook to manage the state for any of the modals defined in ModalWindow.js */
export const useModalState = () => {
    // State and State Mangement
    const [isVisible, setIsVisibile] = useState(false)
    const openModal = () => setIsVisibile(true)
    const closeModal = () => setIsVisibile(false)
    const toggleModal = () => setIsVisibile((current) => !current)
    return {
        isVisible,
        openModal,
        closeModal,
        toggleModal
    }
}
