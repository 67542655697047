import { 
    partialMatchPhrases, 
    exactMatchUsernames,
    thirdPartyEmailDomains
} from "./problematicNames"

/**
 * Takes any input and converts/casts it to a string.
 * @param {*} val Input to be converted/casted to a string
 */
 const convertToString = (val) => {
    return val + ''
}

/**
 * Scrub a string for invalid characters, and ensure it does not exceed a maximum length.
 * Typical data entry from a user does not require and special characters (with the exception of passwords, which this function should not be used for)
 * @param {string} str The string to sanitize
 * @param {number} [maxlength=75] Specify a max length to the string, will remove off any excess.
 * @param {boolean} [toUpper=false] If true, convers str to uppercase.
 * @returns {string} Sanitized string.
 */
 const sanitize = (str, maxlength = 75, toUpper = false) => {
    // Make sure it's a string
    str = convertToString(str)
    // Scrub string of unwanted characters but with support for multiple languages. ('\pL' does not work in Javascript)
    str = str.replace(
        // eslint-disable-next-line no-useless-escape
        /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9 \.,_-]/gim,
        ''
    )
    //str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '')
    // Ensure it does not exceed maxlength
    str = str.substring(0, maxlength)
    // Make it uppercase if required
    if (toUpper) {
        str = str.toUpperCase(str)
    }
    // Return the clean string
    return str
}

/**
 * Combines first and last name, makes it lowercase, and strips whitespace.
 * @param {string} first First name of the user
 * @param {string} last Last name of the user
 * @returns {string} Formatted Username
 */
 const formatUsername = (first, last) => {
    // Combine the First and Last name to be the Username
    let un = `${first}${last}`
    // Ensure no whitespace
    un = un.replace(/\s+/g, '')
    // For conformity, ensure it is all lowercase
    un = un.toLowerCase()
    return un
}

/**
 * Converts UNIX time to a user-friendly date string.
 * @param {int} val UNIX time to convert
 * @returns {string} Formatted string (will be empty string if no value provided)
 */
 const convertUNIXTime = (val) => {
    // Do we have a time to convert? If not just return empty string
    if (!val) return ''
    // Convert it to Javscript date
    let date = new Date(val * 1000)
    // Prepare variables to format our date exactly how we want it
    let month = date.getMonth()+1
    let day = date.getDate()
    let year = date.getFullYear()
    let hours = date.getHours()
    let ampm = 'AM'
    // We want to use 12 hour format, not 24 hour
    if (hours > 12) {
        hours = hours-12
        ampm = 'PM'
    }
    let minutes = date.getMinutes()
    // Enforce 2 digit minute
    if (minutes < 10) minutes = `0${minutes}`
    // Return the date in user-friendly format (MM/DD/YYYY HH:MM AM/PM e.g. 05/03/2021 3:16 PM)
    // Javascript defaults to the timezone of the browser, so no need to worry about that
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`
}

/**
 * Verifies if a string is in email format. Returns true if valid.
 * @param {string} val The string to check.
 */
 const checkEmailFormat = (val) => {
    // Regular expression to validate email.
    // Lifted from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
    // eslint-disable-next-line no-useless-escape
    const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return re.test(val)
}

/**
 * Ensure a string only contains characters that are allowed by API/ActiveDirectory password policy.
 * @param {string} pw The string to validate
 * @returns {boolean} If true then the password is good
 */
 const checkPassword = (pw) => {
    // Ensure we're working with a string
    let str = convertToString(pw)
    // Check string for characters that aren't allowed by API/ActiveDirectory password policy
    // eslint-disable-next-line no-useless-escape
    const re = /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9$&+,:;=?@#|'.^*%!_-]/gim
    return !re.test(str)
}

/**
 * Scrub a password string for invalid characters, and ensure it does not exceed a maximum length.
 * This should only be used for password field, for other sanitization just use the regular sanitize.
 * Should you need to simply verify if a string meets password requirements, use checkPassword
 * @param {string} str The password string to sanitize
 * @param {number} [maxlength=75] Specify a max length to the string, will remove off any excess.
 * @returns {string} Sanitized password.
 */
 const sanitizePassword = (str, maxlength = 75) => {
    // Make sure it's a string
    str = convertToString(str)
    // Scrub string of unwanted characters but with support for multiple languages. ('\pL' does not work in Javascript)
    str = str.replace(
        // eslint-disable-next-line no-useless-escape
        /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9$&+,:;=?@#|'.^*%!_-]/gim,
        ''
    )
    // Ensure it does not exceed maxlength
    str = str.substring(0, maxlength)
    // Return the clean string
    return str
}

/**
 * Helper function for checkUsername()
 * @param {boolean} exact Denotes whether or not the message should be for an exact or partial match
 * @param {string} badName The matched problematic name to insert into the formatted message
 * @returns {string} Formatted message string
 */
const formatCheckUsernameMessage = (exact, badName) => {
    if (exact) {
        return `Invalid Username, "${badName}" is a flagged common Username. Enter a unique Username.`
    }
    // Partial
    return `Username cannot contain "${badName}".`
}

/**
 * Checks a username for flagged words that can cause problems. If a match is found,
 * it will will return a user-friendly string detailing what was caught
 * @param {string} username The username to check
 * @returns {string} end-user friendly message when something is found
 */
const checkUsername = (username) => {
    // Ensure we're working with a string
    let str = convertToString(username)
    // Convert it to all lowercase for matching
    str = str.toLowerCase()
    // Check the string for partialMatches with flagged words (will be null if no matches)
    const regexPartial = new RegExp(partialMatchPhrases.join('|')).exec(str)
    if (regexPartial) {
        // Match was found, return the matched word
        return formatCheckUsernameMessage(false, regexPartial[0])
    }
    // Check the string for exactMatches (will be null if no matches)
    const regexExact = new RegExp('^'+exactMatchUsernames.join('$|^')+'$').exec(str)
    if (regexExact) {
        // Match was found, return the matched word
        return formatCheckUsernameMessage(true, regexExact[0])
    }
    // No matches found, return empty string
    return ''
}

/**
 * Checks an email for any Third Party Company domains to avoid them attempting to create
 * users which we already have created
 * @param {string} email The email to check
 * @returns {boolean} If true, then this is a third party email, and should prevent user creation
 */
const checkForThirdPartyEmail = (email) => {
    // Ensure we're working with a string
    let str = convertToString(email)
    // Check for third party domains
    const regexEmailDomain = new RegExp('@'+thirdPartyEmailDomains.join('|@'))
    return regexEmailDomain.test(str)
}

/**
 * Helper function for one of the pain points of Hosted. When logging into a server,
 * you need the entire domain and username: `hostedfrazer\myusername`. This was a common
 * call with dealers from the previous portal, as sometimes they were simply putting the username,
 * or using the wrong slash, or mispelling the domain. To log into the portal you only need the 
 * username, so we'll handle it for them if they copy/paste credentials from their email, or
 * try to type it in manually.
 * @param {string} username The Username to be formatted and sanitized
 * @returns {string} The formatted and sanitized username string
 */
const loginFormatterHelper = (username) => {
    // Ensure we're working with a string
    let str = convertToString(username)
    // Do we have a slash present?
    if (!new RegExp(/[/\\]/).test(str)) {
        // No slash present, sanitize it and return it
        return sanitize(str)
    }
    // We do have a slash, which one though?
    if (new RegExp(/[\\]/).test(str)) {
        // Backslash is present '\', sanitize everything to the right of it and return it
        return sanitize(str.split('\\')[1])
    } 
    // Assume forward slash '/', sanitize everything to the right of it and return it
    return sanitize(str.split('/')[1])
}

export {
    checkEmailFormat,
    checkPassword,
    convertUNIXTime,
    sanitize,
    sanitizePassword,
    formatUsername,
    formatCheckUsernameMessage,
    checkUsername,
    checkForThirdPartyEmail,
    loginFormatterHelper
}
