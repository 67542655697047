import { useState, useEffect, useContext } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { ResultModal } from '../components/ModalWindow'
import PropTypes from 'prop-types'
import reportError from '../shared/errors'
import {
    sanitize,
    formatUsername,
    checkEmailFormat,
    checkUsername,
    checkForThirdPartyEmail
} from '../shared/formatting'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useQuery, useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'
import { hostedPrices } from '../shared'

// Allow easy resetting of newuser state
const initialNewuser = {
    first: '',
    last: '',
    username: '',
    email: '',
    am: '',
    errormessage: ''
}

const AddNewUserModal = ({
    isVisible = false,
    closeModal,
    cid,
    dealergroup,
    refetch
}) => {
    // State for textfield data
    const [newuser, setNewuser] = useState(initialNewuser)
    // Ensure we have input before the user can attempt to create user
    const [actionDisabled, setActionDisabled] = useState(true)
    // State to determine if any failures were due to validation. Determines actions when closing modal.
    const [validationFailed, setValidationFailed] = useState(false)
    // Checkbox state [{ checked: false, cid, name }]
    const [selectableCIDs, setSelectableCIDs] = useState([])
    // Determine what is displayed
    const [modalState, setModalState] = useState({
        // main, success, failed
        display: 'main',
        message: ''
    })

    // Login context for username to be included in error reporting
    const { loginInfo } = useContext(LoginContext)
    // API Interaction
    const api = useAPIClient()
    // Query to get dealerships in the dealergroup to determine initial server access
    const { refetch: getDealerships } = useQuery(
        [
            {
                qk: 'getDealershipsInDealergroup',
                dealergroup: dealergroup
            }
        ],
        () => api.dealergroups.getDealershipsInDealergroup({ dealergroup }),
        {
            enabled: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: (d) => {
                // Populate our selectableCIDs
                let a = []
                for (let i = 0; i < d.data.length; i++) {
                    // Ensure the CID from props is checked
                    let checked = false
                    if (d.data[i].cid.toString() === cid) {
                        checked = true
                    }
                    // Add to array
                    a.push({
                        checked: checked,
                        cid: d.data[i].cid,
                        name: d.data[i].name
                    })
                }
                setSelectableCIDs(a)
            },
            onError: (error) => {
                // Display failure and report error to Sentry
                reportError(
                    error,
                    'getDealershipsInDealergroup',
                    loginInfo.username
                )
                setModalState({
                    display: 'failed',
                    message:
                        'Unable to fetch available servers in dealergroup to determine access. Please try again.'
                })
            }
        }
    )

    // data of the dealership we are checking the number of users of.
    // Note: Includes request data, their index in the dealership map, and whether
    // the warning should be shown.
    const [dealership, setDealership] = useState()

    // What dealership we're checking the number of users of
    // Default to the CID initially selected.
    const [tierCheckInfo, setTierCheckInfo] = useState(cid)

    // Query to get dealership info to check if adding a user will raise server tier.
    useQuery(
        ['getUsersInCompany', { cid: tierCheckInfo }],
        () => api.dealerships.getDealershipInfo({ cid: tierCheckInfo }),
        {
            // Only query if we have a dealership to look at and a list of possible dealerships
            enabled: !!tierCheckInfo && selectableCIDs.length !== 0,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: ({ data: dealershipData }) => {
                if (
                    !!hostedPrices[dealershipData?.users.length + 1] &&
                    dealershipData?.users.length > 0
                ) {
                    let mapIndex = selectableCIDs.findIndex(
                        (p) => p.name === dealershipData.name
                    )
                    // We're going to exceed a threshhold, so ask to confirm
                    setDealership({
                        ...dealershipData,
                        mapIndex: mapIndex,
                        showWarning: true
                    })
                }
            },
            onError: (error) => {
                // No modal popup or message necessary if this fails.
                // Report to Sentry
                reportError(error, 'getUsersInCompany', loginInfo.username)
            }
        }
    )

    // Mutations
    const { mutate: createUser, isLoading: createIsLoading } = useMutation(
        api.users.createUser
    )
    const {
        mutate: setSecurityGroup,
        isLoading: securityIsLoading
    } = useMutation(api.users.addUserToSecurityGroup)
    const {
        mutate: changePassword,
        isLoading: passwordIsLoading
    } = useMutation(api.users.setPassword)
    const { mutate: enableUser, isLoading: enableIsLoading } = useMutation(
        api.users.enableUser
    )
    // Mutation Handlers, we'll execute this in four parts:
    // 1. Create the user (in Active Directory creating a user without a password will cause it to be disabled)
    // 2. For each CID selected, we'll grant the new user access to those servers.
    //      Also check and warn if adding that user will move us to a new tier.
    // 3. "Reset" the password (this will generate a password, and send the credential email with all install links for each server)
    // 4. Enable User (typically whenever we reset a password we should also Unlock User as well, however that is not necessary here)
    const createUserHandler = () => {
        // 1. Create the user
        // Prevent triggering multiple API calls
        if (
            createIsLoading ||
            securityIsLoading ||
            passwordIsLoading ||
            enableIsLoading
        ) {
            return
        }
        // Ensure username does not exceed max Active Directory length
        if (newuser.username.length > 20) {
            setValidationFailed(true)
            setModalState({
                display: 'failed',
                message:
                    'Username needs to be within 20 characters total. Try abbreviating the First or Last name if possible.'
            })
            return
        }
        // Ensure username of minimum length of 6 characters (this number is arbitrary, agreed upon by the Hosted Team)
        if (newuser.username.length < 6) {
            setValidationFailed(true)
            setModalState({
                display: 'failed',
                message: 'Username needs to be at least 6 characters long.'
            })
            return
        }
        // Ensure username is not a problematic username
        let message = checkUsername(newuser.username)
        if (message) {
            setValidationFailed(true)
            setModalState({
                display: 'failed',
                message: message
            })
            return
        }
        // Ensure email is valid
        if (!checkEmailFormat(newuser.email)) {
            setValidationFailed(true)
            setModalState({
                display: 'failed',
                message: 'Please ensure a valid email address is entered.'
            })
            return
        }
        // Ensure email does not belong to a Third Party Company
        if (checkForThirdPartyEmail(newuser.email)) {
            setValidationFailed(true)
            setModalState({
                display: 'failed',
                message: `This user appears to belong to a Third Party company. If you wish to grant them access to Hosted Frazer,
                give us a call at 888-963-5369, or email our support at help@frazer.com`
            })
            return
        }
        // Ensure the user will be granted access to at least one server (prevent useless users)
        if (selectableCIDs.filter((entry) => entry.checked).length < 1) {
            setValidationFailed(true)
            setModalState({
                display: 'failed',
                message:
                    'Please specify at least 1 server that the user can access.'
            })
            return
        }
        // With input validated, now we'll actually create the user
        createUser(
            {
                qk: 'createUser',
                dealergroup: dealergroup,
                username: newuser.username,
                firstname: newuser.first,
                lastname: newuser.last,
                email: newuser.email,
                password: ''
            },
            {
                onSuccess: () => {
                    // Move onto step 2
                    setSecurityGroupsHandler()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'createUser', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message: `Failed to create user. ${
                            error.status === 409
                                ? 'User already exists.'
                                : 'Please try again.'
                        }`
                    })
                }
            }
        )
    }
    // 2. Grant Access to specified CIDs
    const setSecurityGroupsHandler = async () => {
        // Fire requests
        for (let i = 0; i < selectableCIDs.length; i++) {
            // Skip if not checked
            if (!selectableCIDs[i].checked) {
                continue
            }

            // await keyword triggers API calls sequentially
            await setSecurityGroup(
                {
                    qk: 'addUserToSecurityGroup',
                    username: newuser.username,
                    cid: selectableCIDs[i].cid
                },
                {
                    onError: (error) => {
                        // Display failure and report error to Sentry
                        reportError(
                            error,
                            'setSecurityGroup',
                            loginInfo.username
                        )
                        setModalState({
                            display: 'failed',
                            message: `User created, however a problem occurred while granting access to specified servers. Failed CID: ${selectableCIDs[i].cid}.
                            Credential email has NOT been sent. You can locate this user and grant server access manually.
                            Once completed, perform a password reset to send the user credentials.
                            If issue persists, reach out to us at 888-963-5369, or email us at help@frazer.com.`
                        })
                        return
                    }
                }
            )
        }
        // Move onto step 3
        changePasswordHandler()
    }
    // 3. "Reset" (generate) password and email credentials
    const changePasswordHandler = () => {
        // Fire request
        changePassword(
            {
                qk: 'changePassword',
                username: newuser.username,
                password: ''
            },
            {
                onSuccess: () => {
                    // Move onto step 4
                    enableUserHandler()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'changePassword', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message: `User created, access granted to all selected servers, however failed to set password and email credentials.
                        To fix this you can perform a password reset to send credentials to the user.
                        If issue persists, reach out to us at 888-963-5369, or email us at help@frazer.com.`
                    })
                }
            }
        )
    }
    // 4. Enable the user account
    const enableUserHandler = () => {
        // Fire request
        enableUser(
            { qk: 'enableUser', username: newuser.username },
            {
                onSuccess: () => {
                    // Display success, reset newuser, refetch fresh company data to reflect change
                    setModalState({
                        display: 'success',
                        message: `User: ${
                            newuser.username
                        } created successfully, granted server access for CID(s): ${selectableCIDs
                            .filter((entry) => entry.checked)
                            .map((entry) => entry.cid)
                            .join(
                                ', '
                            )}, and credentials have been emailed to ${
                            newuser?.email
                        }`
                    })
                    setNewuser(initialNewuser)
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'enableUser', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message: `User created, access granted to all selected servers, password set and credentials emailed,
                        however failed to ensure the user is enabled. If the user is unable to log into the server, you
                        can perform a password reset which will enable the user. If issue persists, reach out to us
                        at 888-963-5369, or email us at help@frazer.com.`
                    })
                }
            }
        )
    }
    // Update the Username whenever first or last is changed, and enable action if we have input
    useEffect(() => {
        // Format the username
        let un = formatUsername(newuser.first, newuser.last)
        setNewuser((prevNewuser) => ({ ...prevNewuser, username: un }))
        // Check if we have input and enable/disable action
        let disable =
            newuser.first.length === 0 ||
            newuser.last.length === 0 ||
            newuser.email.length === 0 ||
            selectableCIDs.filter((cid) => cid.checked).length === 0
        setActionDisabled(disable)
        // Having newuser as dependency causes infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newuser.first, newuser.last, newuser.email, selectableCIDs])

    // Reset state whenever isVisible is toggled
    useEffect(() => {
        setActionDisabled(true)
        setValidationFailed(false)
        setNewuser(initialNewuser)
        setModalState({ display: 'main', message: '' })
        setSelectableCIDs([])
        setTierCheckInfo(cid)
        if (isVisible) {
            getDealerships()
        }
        // Having getDealerships as dependency causes infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])
    return (
        <>
            {modalState.display === 'main' && (
                <ModalWindow
                    open={isVisible}
                    title="Create a New User"
                    actionApply={createUserHandler}
                    actionApplyDisabled={
                        createIsLoading ||
                        securityIsLoading ||
                        passwordIsLoading ||
                        enableIsLoading ||
                        actionDisabled
                    }
                    textApply={
                        createIsLoading ||
                        securityIsLoading ||
                        passwordIsLoading ||
                        enableIsLoading
                            ? 'Creating User, Emailing Credentials...'
                            : 'Create User and Email Credentials'
                    }
                    actionCancel={closeModal}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="First Name"
                                autoComplete="off"
                                value={newuser.first}
                                fullWidth
                                onChange={(e) => {
                                    e.persist()
                                    setNewuser((prevNewuser) => ({
                                        ...prevNewuser,
                                        first: sanitize(e.target.value)
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Last Name"
                                autoComplete="off"
                                value={newuser.last}
                                fullWidth
                                onChange={(e) => {
                                    e.persist()
                                    setNewuser((prevNewuser) => ({
                                        ...prevNewuser,
                                        last: sanitize(e.target.value)
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email Address"
                                // autoComplete helps prevent browser from autofilling login for new user.
                                autoComplete="off"
                                value={newuser.email}
                                type="email"
                                fullWidth
                                onChange={(e) => {
                                    e.persist()
                                    setNewuser((prevNewuser) => ({
                                        ...prevNewuser,
                                        email: e.target.value
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>
                                    Specify which servers this user should have
                                    access to:
                                </Typography>
                            </Grid>
                            {selectableCIDs &&
                                selectableCIDs.map((d, index) => (
                                    <Grid
                                        container
                                        alignItems="center"
                                        item
                                        xs={12}
                                        key={`${index}-CID:${d.cid}`}
                                    >
                                        <Grid item>
                                            <Checkbox
                                                checked={d.checked}
                                                onChange={() => {
                                                    setSelectableCIDs(
                                                        (prevState) => {
                                                            let ds = [
                                                                ...prevState
                                                            ]
                                                            ds[
                                                                index
                                                            ].checked = !ds[
                                                                index
                                                            ].checked
                                                            return ds
                                                        }
                                                    )
                                                    setTierCheckInfo(
                                                        d.checked ? d.cid : null
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography noWrap>
                                                {`${d.cid} ${d.name}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                    <ModalWindow
                        open={dealership?.showWarning}
                        actionApply={() => {
                            setDealership((prevState) => ({
                                ...prevState,
                                showWarning: false
                            }))
                        }}
                        actionCancel={() => {
                            // deselect this guy from the list
                            setSelectableCIDs((prevState) => {
                                let ds = [...prevState]
                                ds[dealership?.mapIndex].checked = !ds[
                                    dealership?.mapIndex
                                ].checked
                                return ds
                            })
                            setDealership((prevState) => ({
                                ...prevState,
                                showWarning: false
                            }))
                            setTierCheckInfo(null)
                        }}
                    >
                        Adding a new user to {dealership?.name} will increase
                        your dealership tier to level{' '}
                        {hostedPrices[dealership?.users?.length + 1]?.tier}.
                        This will raise your price to $
                        {hostedPrices[dealership?.users?.length + 1]?.price} per month.
                        Would you like to continue?
                    </ModalWindow>
                </ModalWindow>
            )}
            {modalState.display === 'success' && (
                <ResultModal
                    open={isVisible}
                    title="Success"
                    actionCancel={closeModal}
                    iconType="success"
                    message={modalState.message}
                    copyButton
                />
            )}
            {modalState.display === 'failed' && (
                <ResultModal
                    open={isVisible}
                    title="Error"
                    actionCancel={() => {
                        if (validationFailed) {
                            // Just set display back to main so user can fix validation problems
                            setModalState({ display: 'main', message: '' })
                            return
                        }
                        closeModal()
                    }}
                    iconType="error"
                    message={modalState.message}
                />
            )}
        </>
    )
}

export default AddNewUserModal

AddNewUserModal.propTypes = {
    /** Determines whether or not to show the modal */
    isVisible: PropTypes.bool.isRequired,
    /** Function to set isVisible to false. Usually from the useModalState hook */
    closeModal: PropTypes.func.isRequired,
    /** CID of the company's page that this modal was called from. Used for setting initial server access selection */
    cid: PropTypes.string,
    /** Dealergroup that the user belongs to */
    dealergroup: PropTypes.string,
    /** Function to refetch fresh data after a successful mutation */
    refetch: PropTypes.func.isRequired
}
