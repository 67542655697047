import * as Sentry from '@sentry/react'

/**
 * Report an error with tags to Sentry
 * @param {*} error The error to report, Sentry allows any type
 * @param {string} action The action that was being taken when error occurred
 */
const reportError = (error, action, user) => {
    const scope = new Sentry.Scope()
    // Add tags
    scope.setTag('action', action)
    scope.setTag('username', user)
    // Report to Sentry
    Sentry.captureException(error, scope)
}

export default reportError
