import { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { ResultModal } from '../components/ModalWindow'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import reportError from '../shared/errors'
import { useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'

// Styling
const iconSize = { height: 128, width: 128 }

const ChangeAccountTypeModal = ({
    isVisible,
    closeModal,
    username,
    accountManager,
    refetch
}) => {
    // Determine what is displayed
    const [modalState, setModalState] = useState({
        // main, success, failed
        display: 'main',
        message: ''
    })
    // Login context for username to be included in error reporting
    const { loginInfo } = useContext(LoginContext)
    // API Interaction
    const api = useAPIClient()
    // Mutations
    const { mutate: promoteAM, isLoading: promoteIsLoading } = useMutation(() =>
        api.users.grantAccountManager({ username })
    )
    const { mutate: demoteAM, isLoading: demoteIsLoading } = useMutation(() =>
        api.users.revokeAccountManager({ username })
    )
    // Mutation Handlers
    const promoteAMHandler = () => {
        // Prevent triggering multiple API calls
        if (promoteIsLoading) {
            return
        }
        // Fire request
        promoteAM(
            { qk: 'grantAccountManager', username: username },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `User: ${username} has been successfully elevated to Account Manager`
                    })
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(
                        error,
                        'grantAccountManager',
                        loginInfo.username
                    )
                    setModalState({
                        display: 'failed',
                        message: `Failed to elevate ${username} to Account Manager. Please try again.`
                    })
                }
            }
        )
    }
    const demoteAMHandler = () => {
        // Prevent triggering multiple API calls
        if (demoteIsLoading) {
            return
        }
        // Fire request
        demoteAM(
            { qk: 'revokeAccountManager', username: username },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `User: ${username} has been set to Standard User`
                    })
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(
                        error,
                        'revokeAccountManager',
                        loginInfo.username
                    )
                    setModalState({
                        display: 'failed',
                        message: `Failed to set ${username} to Standard User. Please try again.`
                    })
                }
            }
        )
    }
    // Reset state whenever isVisible is toggled
    useEffect(() => {
        setModalState({ display: 'main', message: '' })
        // Including accountManager could cause the modal to re-appear automatically once fresh data is fetched after mutation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])
    return (
        <>
            {modalState.display !== 'success' &&
                modalState.display !== 'failed' && (
                    <ModalWindow
                        open={isVisible}
                        // Dual purpose modal, content and actions depend on accountType state (updated in useEffect when user data fetched)
                        actionApply={() => {
                            if (!accountManager) {
                                promoteAMHandler()
                            } else {
                                demoteAMHandler()
                            }
                        }}
                        actionApplyDisabled={
                            promoteIsLoading || demoteIsLoading
                        }
                        // Determine action (promote/demote), then determine appropriate action text (send/sending)
                        textApply={
                            !accountManager
                                ? promoteIsLoading
                                    ? 'Promoting User...'
                                    : 'Promote User'
                                : demoteIsLoading
                                ? 'Demoting User...'
                                : 'Demote User'
                        }
                        actionCancel={closeModal}
                        title="Edit Account Type"
                    >
                        <Grid container justify="center">
                            <Grid container justify="center" item xs={12}>
                                <Grid item>
                                    <HelpOutlineIcon
                                        color="secondary"
                                        style={iconSize}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="center" item xs={12}>
                                <Grid item>
                                    <Typography>
                                        Are you sure you wish to{' '}
                                        {accountManager ? 'demote' : 'promote'}{' '}
                                        <strong>{username}</strong> to{' '}
                                        <strong>
                                            {accountManager
                                                ? 'Standard User'
                                                : 'Account Manager'}
                                        </strong>
                                        ?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ModalWindow>
                )}
            {modalState.display === 'success' && (
                <ResultModal
                    open={isVisible}
                    title="Success"
                    actionCancel={closeModal}
                    iconType="success"
                    message={modalState.message}
                    copyButton
                />
            )}
            {modalState.display === 'failed' && (
                <ResultModal
                    open={isVisible}
                    title="Error"
                    actionCancel={closeModal}
                    iconType="error"
                    message={modalState.message}
                />
            )}
        </>
    )
}

export default ChangeAccountTypeModal

ChangeAccountTypeModal.propTypes = {
    /** Determines whether or not to show the modal */
    isVisible: PropTypes.bool.isRequired,
    /** Function to set isVisible to false. Usually from the useModalState hook */
    closeModal: PropTypes.func.isRequired,
    /** The username of the user to set account type for */
    username: PropTypes.string,
    /** Whether or not the user is an account manager, determines promote/demote action */
    accountManager: PropTypes.bool,
    /** Function to refetch fresh data after a successful mutation */
    refetch: PropTypes.func.isRequired
}
