import { useState, useContext } from 'react'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Avatar from '@material-ui/core/Avatar'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useTheme } from '@material-ui/core/styles'
import { useMutation } from 'react-query'
import { LoginContext } from '../contexts/LoginContext'
import logo from '../images/hosted-logo-yellow.png'
import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './ErrorFallbackUI'

// TODO IE: Logout
// import { useAPIClient } from 'react-toolbox/APIClient'

/**
 * Top Navigation Bar that is included in the layout for every page once logged in.
 */
const Navbar = () => {
    // Styling / Spacing
    const theme = useTheme()
    const { loginInfo, clearLoginInfo } = useContext(LoginContext)
    // API Interaction
    const api = useAPIClient()
    // Mutation
    const { mutate: logOut, isLoading } = useMutation(api.auth.logout)
    // Mutation Handler
    const logoutHandler = () => {
        // In this case we aren't concerned with success or failure.
        // We clear the token locally here, and logging in generates new token.
        // If the logout fails, the API will purge the token when it expires.
        logOut()
        clearLoginInfo('You have successfully logged out.')
    }

    // State / Context
    const [anchorelement, setAnchorelement] = useState()

    const handleClick = (event) => {
        setAnchorelement(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorelement(null)
    }
    return (
        <>
            <IconButton edge="start">
                <img
                    src={logo}
                    alt="logo"
                    style={{ height: '58px', width: '107px' }}
                />
            </IconButton>
            <Typography variant="h5" nowrap="true">
                Hosted 2 Dealer Portal
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <ErrorBoundary fallback={<ErrorFallbackUI />}>
                <IconButton color="inherit" onClick={handleClick}>
                    <Typography>{loginInfo.displayName} </Typography>&nbsp;
                    <AccountCircle color="secondary" />
                </IconButton>
                <Menu
                    anchorEl={anchorelement}
                    keepMounted
                    open={!!anchorelement}
                    onClose={handleClose}
                >
                    <MenuItem>
                        <Grid container justify="center">
                            <Grid container item justify="center" xs={12}>
                                <Avatar
                                    style={{
                                        width: theme.spacing(7),
                                        height: theme.spacing(7),
                                        backgroundColor:
                                            theme.palette.common.black
                                    }}
                                >
                                    <AccountCircle
                                        color="secondary"
                                        style={{
                                            width: theme.spacing(7),
                                            height: theme.spacing(7),
                                            backgroundColor:
                                                theme.palette.common.black
                                        }}
                                    />
                                </Avatar>
                            </Grid>
                            <Grid container item justify="center" xs={12}>
                                <Typography>{loginInfo.username}</Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem onClick={logoutHandler} disabled={isLoading}>
                        <Grid container justify="center">
                            <Grid
                                item
                                style={{
                                    paddingRight: theme.spacing(1)
                                }}
                            >
                                <ExitToAppIcon color="secondary" />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {isLoading ? 'Signing out' : 'Sign out'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
            </ErrorBoundary>
        </>
    )
}

export default Navbar
