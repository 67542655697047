const dealergroups = {
    getDealershipsInDealergroup: ({ dealergroup }) => ({
        path: `/dealergroups/${dealergroup}/dealerships`,
        method: 'GET'
    }),
    getUsersInDealergroup: ({ dealergroup }) => ({
        path: `/dealergroups/${dealergroup}/users`,
        method: 'GET'
    })
}

export default dealergroups
