/**
 * Sorts a tableData array by specified index (column). Returns an array sorted by ascending order. 
 * If you wish for descending, simply follow this sort by a .reverse().
 * If unable to sort due to malformed tableData, it will simply return the unsorted data
 * @param {Object[]} tableData The array of data to be sorted
 * @param {string} [tableData[].navpath] Optional path to navigate to when the row of table is clicked
 * @param {Object[]} tableData[].row Row in the table
 * @param {Function} [tableData[].row[].formatter] Optional formatter function that the table will apply to data
 * @param {*} tableData[].row[].data The actual data for the table cell
 * @param {number} sortColumn The index to sort the array of arrays by (basically specifies the column)
 * @param {string} [sortType=string] Specifying the type improves sort results. Defaults to "string", other acceptable values: "number", "boolean", "array", anything else will not sort properly
 * @returns {[]} Sorted Array in ascending order
 */
 const sortTableData = (tableData, sortColumn, sortType = 'string') => {
    // If tableData is malformed, it throws errors
    try {
        let td = tableData.sort((a, b) => {
            // Do both A and B match the specified type? (Arrays are typeof 'object', not 'array', we handle those farther down)
            if (typeof a.row[sortColumn].data === sortType && typeof b.row[sortColumn].data === sortType) {
                // Yes, then compare them
                switch (sortType) {
                    case 'string':
                        return a.row[sortColumn].data.localeCompare(b.row[sortColumn].data)
                    case 'number':
                    case 'boolean':
                        return a.row[sortColumn].data - b.row[sortColumn].data
                    default:
                        // Not an Array or other type that we expect, just move it up
                        return -1
                }
            }
            // No, does at least A match the keyType?
            if (typeof a.row[sortColumn].data === sortType) {
                // Yes, move it down the array
                return 1
            } 
            // No, is our sortType 'array'?
            if (sortType === 'array') {
                // Yes, are both A and B arrays?
                if (Array.isArray(a.row[sortColumn].data) && Array.isArray(b.row[sortColumn].data)) {
                    // Yes, compare the arrays as strings by joining them
                    return a.row[sortColumn].data.join().localeCompare(b.row[sortColumn].data.join())
                }
                // No, is at least A an array?
                if (Array.isArray(a.row[sortColumn].data)) {
                    // Yes, move it down the array
                    return 1
                }
                // No, move it up
                return -1   
            } 
            // No, just move it up
            return -1
        })
        // Success, return the sorted data
        return td
    } catch(error) {
        // TODO IE: Determine what should be done with the error, if anything. For now, just console.log it
        console.log(error)
        // Failure, just return the unsorted data
        return tableData
    }
}

export { 
    sortTableData
}
