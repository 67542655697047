import { useState, useEffect, useContext } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { useModalState } from '../components/ModalWindow'
import { navigate } from '@reach/router'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useMutation } from 'react-query'
import logo from '../images/hosted-logo-yellow.png'
import { loginFormatterHelper, sanitizePassword } from '../shared/formatting'
import reportError from '../shared/errors'

/**
 * Login / landing page when the user visits the site.
 */
const Login = () => {
    // Styling / Spacing
    const theme = useTheme()
    // We mainly need the token setter, but we also use the message from loginInfo to inform the user
    // if they were logged out automatically due to session expiring
    const { loginInfo, initializeLoginInfo } = useContext(LoginContext)
    // Field state
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showpassword, setShowpassword] = useState(false)
    // Error messaging. Both lines need to be present in order for either to render.
    const [messages, setMessages] = useState({
        line1: '',
        line2: ''
    })
    // Password Privacy
    const togglePasswordVisibility = () => {
        setShowpassword((curr) => !curr)
    }
    // Modal Management
    const { isVisible, openModal, closeModal } = useModalState()
    // API Interaction
    const api = useAPIClient()
    // Mutations
    const { mutate: logIn, isLoading } = useMutation(api.auth.login)
    // Mutation Handlers
    // https://react-query.tanstack.com/docs/guides/mutations
    // Note that since version 1.1.0, the mutate function is no longer called synchronously so you cannot use it in an event callback.
    // This is due to React event pooling.
    /** Login Handler */
    const logInHandler = () => {
        // Prevent triggering multiple API calls
        if (isLoading) {
            return
        }
        // Ensure username is properly formatted and sanitized
        const scrubbedUsername = loginFormatterHelper(username)
        // Ensure password is sanitized
        const scrubbedPassword = sanitizePassword(password)
        // Ensure neither username or password is empty
        if (scrubbedUsername === "" || scrubbedPassword === "") {
            setMessages({ line1: 'Invalid Input', line2: 'Please enter your Username and Password to log in.' })
            return
        }
        // Fire request
        logIn(
            {
                qk: 'login',
                username: scrubbedUsername,
                password: scrubbedPassword
            },
            {
                onSuccess: (data) => {
                    // Reset messages
                    setMessages({ line1: '', line2: '' })
                    // Set our Token (which effectively completes the login, revealing the Dashboard)
                    initializeLoginInfo(data.data.token, scrubbedUsername)
                },
                onError: (error) => {
                    // Report to Sentry
                    reportError(error, 'login', username)
                    setMessages({
                        line1: 'Login Failed',
                        line2:
                            'Please ensure username and password are correct and try again.'
                    })
                }
            }
        )
    }
    // Effects
    useEffect(() => {
        // On page load navigate to base path
        navigate('/')
    }, [])
    return (
        <div>
            <main style={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    style={{ height: '100%' }}
                    wrap="nowrap"
                    spacing={2}
                >
                    <Grid item>
                        <AppBar
                            position="static"
                            style={{ background: theme.palette.primary.dark }}
                        >
                            <Toolbar disableGutters>
                                <Grid container justify="center">
                                    <Grid item>
                                        <img
                                            src={logo}
                                            alt="logo"
                                            style={{
                                                width: '300px',
                                                height: 'auto'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        style={{ height: '100%' }}
                        justify="center"
                        xs={12}
                    >
                        <Grid item style={{ height: '100px' }}></Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        alignContent="center"
                        justify="center"
                    >
                        <Grid item>
                            {loginInfo.message && (
                                <>
                                    <Typography>{loginInfo.message}</Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            alignItems="stretch"
                            style={{ height: '100%' }}
                            wrap="nowrap"
                            spacing={2}
                        >
                            <Grid
                                container
                                item
                                direction="row"
                                style={{ height: '100%' }}
                                justify="center"
                                xs={12}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                >
                                    <TextField
                                        label="Username"
                                        variant="outlined"
                                        fullWidth
                                        value={username}
                                        onChange={(e) => {
                                            setMessages({ line1: '', line2: '' })
                                            setUsername(e.target.value)
                                        }}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="row"
                                style={{ height: '100%' }}
                                justify="center"
                                xs={12}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                >
                                    <TextField
                                        label="Password"
                                        variant="outlined"
                                        fullWidth
                                        value={password}
                                        onChange={(e) => {
                                            setMessages({ line1: '', line2: '' })
                                            setPassword(sanitizePassword(e.target.value))
                                        }}
                                        type={
                                            showpassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                logInHandler()
                                                e.preventDefault()
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() =>
                                                            togglePasswordVisibility()
                                                        }
                                                    >
                                                        {showpassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="row"
                                style={{ height: '100%' }}
                                justify="center"
                                xs={12}
                            >
                                <Grid item>
                                    <Link onClick={openModal}>
                                        Forgot Password?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="row"
                                style={{ height: '100%' }}
                                justify="center"
                                xs={12}
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={isLoading}
                                        size="large"
                                        style={{ width: '150px' }}
                                        onClick={() => logInHandler()}
                                    >
                                        {isLoading
                                            ? 'Logging in'
                                            : 'Log In'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        style={{ height: '100%' }}
                        justify="center"
                        xs={12}
                    >
                        <Grid item>
                            {messages.line1 && messages.line2 && (
                                <>
                                    <Typography color="error">
                                        {messages.line1}
                                    </Typography>
                                    <Typography color="error">
                                        {messages.line2}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item></Grid>
                    <Grid container justify="center" item>
                        <Grid item>
                            <Typography variant="subtitle2">
                                <i>{`Copyright © ${new Date().getFullYear()} Frazer Computing, LLC.  All Rights Reserved`}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <ModalWindow
                    open={isVisible}
                    title="Forgot Password"
                    actionCancel={closeModal}
                    textCancel="Close"
                    singleButton
                >
                    <Typography>
                        If you would like your password reset, please email{' '}
                        <b>hosted@frazer.com</b> or call us at{' '}
                        <b>1-888-963-5369</b> for assistance.
                    </Typography>
                </ModalWindow>
            </main>
        </div>
    )
}

export default Login
