// Here we define combinations of characters that could cause problems if they were used as usernames
// Every day there are millions of bogus login attempts against our servers with names like 
// "admin", "frontdesk", "administrator", "administrador", etc. We need to prevent Dealers from
// creating targeted usernames as it would just end up with the account being seemingly perma-locked
// due to all the failed login attempts.

// We define them here separate to allow easy and clear changes in the future.
// When checking usernames, ensure they are lowercase first.

// While some of these are below the minimum character limit of 6 characters, 
// they are included for future proofing, as the minimum limit is an arbitrary number
// decided upon by the Hosted Team.

// These are words that throw a flag if they are exact match
// Since these are later joined into a regex, some of the usernames contain regex
// example: 'user[0-9]+' instead of 'user1', 'user2', 'user3', etc
const exactMatchUsernames = [
    // 'admin', 'administrator', etc are all caught by partialMatchPhrases
    'manager',
    'manager[0-9]+',
    'frontdesk',
    'frontdesk[0-9]+',
    'anonymouslogon',
    'owner',
    'everyone',
    'todos',
    'server',
    'system32',
    'test',
    'demo',
    'sql',
    'pos',
    'user',
    'user[0-9]+',
    'sales',
    'sales[0-9]+',
    'salesdesk',
    'salesdesk[0-9]+',
    'finance',
    'finance[0-9]+',
    'financedesk',
    'financedesk[0-9]+',
    'service',
    'service[0-9]+',
    'login',
    'login[0-9]+',
    'shop',
    'shop[0-9]+'
]

// These are words that throw a flag if they are detected within a string
const partialMatchPhrases = [
    'admin'
]

// These email domains are reserved for our third party companies.
// We should prevent dealers from creating users for third party companies as we already have users
// for them, we just need to verify their signed agreement and grant access
const thirdPartyEmailDomains = [
    'primalend.com',   // Primalend
    'acacceptance.com' // Spartan Financial
]

export {
    exactMatchUsernames,
    partialMatchPhrases,
    thirdPartyEmailDomains
}