import AllUsers from '../pages/AllUsers'
import Company from '../pages/Company'
import User from '../pages/User'
import { Router } from '@reach/router'

/**
 * Collection of pages and their paths that can be viewed once logged in.
 */
const PageList = () => {
    return (
        <Router>
            <User self path="/" />
            <AllUsers path="/users/" />
            <User path="/users/:username" />
            <Company path="/dealerships/:cid" />
        </Router>
    )
}

export default PageList
