const auth = {
    login: ({ username, password }) => ({
        path: '/login/',
        method: 'POST',
        body: { username, password }
    }),
    logout: () => ({
        path: '/logout/',
        method: 'POST',
        body: {}
    })
}

export default auth
