import { useContext } from 'react'
import auth from '../api/auth'
import endpoints from '../api'
import DashboardLayout from '../layouts/DashboardLayout'
import HostedTheme from '../contexts/HostedTheme'
import Login from '../pages/Login'
import { LoginContext } from '../contexts/LoginContext'
import PageList from './PageList'
import { APIClientProvider } from 'react-toolbox/APIClient'
import { ErrorBoundary } from '@sentry/react'
import ErrorFallbackUI from './ErrorFallbackUI'

function WrappedApp() {
    // Only need the token for APIClient
    const { loginInfo } = useContext(LoginContext)
    return (
        <ErrorBoundary fallback={<ErrorFallbackUI full={true} />}>
            <HostedTheme>
                {loginInfo.token ? (
                    <APIClientProvider
                        baseURL={process.env.REACT_APP_BASE_URL}
                        endpoints={endpoints}
                        opts={{
                            headers: {
                                Authorization: `Bearer ${loginInfo.token}`
                            }
                        }}
                    >
                        <DashboardLayout>
                            <PageList />
                        </DashboardLayout>
                    </APIClientProvider>
                ) : (
                    <APIClientProvider
                        baseURL={process.env.REACT_APP_BASE_URL}
                        endpoints={{ auth }}
                    >
                        <Login />
                    </APIClientProvider>
                )}
            </HostedTheme>
        </ErrorBoundary>
    )
}

export default WrappedApp
