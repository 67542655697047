import { useState, useEffect, useContext } from 'react'
import AddNewUserModal from '../modals/AddNewUserModal'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useParams } from '@reach/router'
import Page from '../components/Page'
import SectionBar from '../components/SectionBar'
import TableCompact from '../components/TableCompact'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { useModalState } from '../components/ModalWindow'
import { useQuery } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'
import { convertUNIXTime } from '../shared/formatting'
import { LoginContext } from '../contexts/LoginContext'
import reportError from '../shared/errors'

// TODO IE: Page interaction
// In-line Styling
// const buttonStyle = {
//     minWidth: '195px'
// }

// Table Headers
const headers = [
    {
        sortable: true,
        sortType: 'string',
        label: 'Name'
    },
    {
        sortable: true,
        sortType: 'string',
        label: 'Username'
    },
    {
        sortable: true,
        sortType: 'string',
        label: 'Email'
    },
    {
        sortable: true,
        sortType: 'boolean',
        label: 'Account Manager'
    },
    {
        sortable: true,
        sortType: 'number',
        label: 'Last Login'
    }
]

/** Page that displays information regarding a Company */
const Company = () => {
    // Users table data
    const [tableData, setTableData] = useState([])
    // Router URL Parameters (In this case we should expect cid)
    const params = useParams()
    // Get user logininfo for error reporting
    const { loginInfo } = useContext(LoginContext)
    // Modal control
    const {
        isVisible: addUserIsVisible,
        openModal: openAddUserModal,
        closeModal: closeAddUserModal
    } = useModalState()
    // API Interaction
    const api = useAPIClient()
    // Queries
    // Get Company Info and Users
    const { data: dealership, refetch: getCompanyInfo } = useQuery(
        ['getUsersInCompany', { cid: params.cid }],
        () => api.dealerships.getDealershipInfo({ cid: params.cid }),
        {
            onSuccess: (d) => {
                if (d?.data && Array.isArray(d.data.users)) {
                    prepareTableData(d.data.users)
                }
            },
            onError: (error) => {
                // No modal popup or message necessary if this fails.
                // Report to Sentry
                reportError(error, 'getDealershipInfo', loginInfo.username)
            }
        }
    )
    /** Helper function to prepare table data for TableCompact component */
    const prepareTableData = (users) => {
        // Prepare tableData
        let td = users.map((user) => ({
            navpath: `/users/${user.username}`,
            row: [
                { data: user.displayname },
                { data: user.username },
                { data: user.email },
                { data: user.admin, formatter: (val) => (val ? 'Yes' : '') },
                {
                    data: user.lastlogon,
                    formatter: (val) => convertUNIXTime(val)
                }
            ]
        }))
        // Update the state
        setTableData(td)
    }
    useEffect(() => {
        setTableData([])
    }, [params.cid])
    return (
        <Page>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SectionBar text="Company Information" />
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={2}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Frazer CID:</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography>
                            <b>{params.cid}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={2}>
                    <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Typography>Company Name:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Grid item>
                            <Typography>
                                <b>{dealership?.data.name}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {dealership?.data.thirdparties &&
                    Array.isArray(dealership.data.thirdparties) &&
                    dealership.data.thirdparties.length > 0 && (
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            justify="flex-start"
                        >
                            <Grid
                                container
                                item
                                xs={4}
                                sm={4}
                                md={3}
                                lg={2}
                                justify="flex-end"
                            >
                                <Grid item>
                                    <Tooltip
                                        arrow
                                        title="Third Party Access to your server is only granted when we have a signed agreement between you, the third party, and us (Frazer). Third Party users are separate from yours, and do not impact your pricing. If you would like more information, give our support a call at 888-963-5369"
                                    >
                                        <Typography>
                                            Third Party Access:
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={3}
                                xl={2}
                            >
                                {dealership?.data.thirdparties?.map((tp) => (
                                    <Grid
                                        item
                                        xs={12}
                                        key={`thirdparty-${tp.id}`}
                                    >
                                        <Typography noWrap>
                                            <strong>{tp.displayname}</strong>
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}

                <Grid container item xs={12}>
                    <SectionBar text={`Company Users ${
                        tableData.length !== 0 ? 
                        `(${tableData.length})` : ""
                    }`} />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid container item>
                        <TableCompact headers={headers} tableData={tableData} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={openAddUserModal}
                        >
                            Create New User
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <AddNewUserModal
                isVisible={addUserIsVisible}
                closeModal={closeAddUserModal}
                dealergroup={dealership?.data.dealergroup}
                cid={params.cid}
                refetch={getCompanyInfo}
            />
        </Page>
    )
}

export default Company
