import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

/** Prominent horizontal divider with label to bring emphasis on a section. */
const SectionBar = ({ text }) => {
    const theme = useTheme()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography 
                    variant="h6"
                    style={{ 
                        paddingLeft: theme.spacing(1),
                        fontWeight: 'bold', 
                        textTransform: 'uppercase'
                    }}
                >{text}</Typography>
                <Divider color="secondary" variant="fullWidth"/>
            </Grid>
        </Grid>
    )
}

export default SectionBar

SectionBar.propTypes = {
    /** A string of text that labels the section divider */
    text: PropTypes.string
}
