import { useState, useEffect, useContext } from 'react'
import { LoginContext } from '../contexts/LoginContext'
import ModalWindow, { ResultModal } from '../components/ModalWindow'
import PropTypes from 'prop-types'
import reportError from '../shared/errors'
import TextField from '@material-ui/core/TextField'
import { checkEmailFormat } from '../shared/formatting'
import { useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'

const ChangeEmailModal = ({
    isVisible,
    closeModal,
    username,
    currentEmail,
    refetch
}) => {
    // Allow the user to modify email address
    const [newEmail, setNewEmail] = useState(currentEmail)
    // Determine what is displayed
    const [modalState, setModalState] = useState({
        // main, success, failed
        display: 'main',
        message: ''
    })
    // Login context for username to be included in error reporting
    const { loginInfo } = useContext(LoginContext)
    // API Interaction
    const api = useAPIClient()
    // Mutation
    const { mutate: changeEmail, isLoading } = useMutation(() =>
        api.users.setEmail({
            username,
            email: newEmail
        })
    )
    // Mutation Handler
    const changeEmailHandler = () => {
        // Prevent triggering multiple API calls
        if (isLoading || currentEmail === newEmail) {
            return
        }
        // Ensure email is valid
        if (!checkEmailFormat(newEmail)) {
            setModalState({
                display: 'failed',
                message: 'Please ensure a valid email address is entered.'
            })
            return
        }
        // Fire request
        changeEmail(
            {
                qk: 'changeEmail',
                username: username,
                email: newEmail
            },
            {
                onSuccess: () => {
                    setModalState({
                        display: 'success',
                        message: `Email Address for User: ${username} successfully updated to ${newEmail}.`
                    })
                    refetch()
                },
                onError: (error) => {
                    // Display failure and report error to Sentry
                    reportError(error, 'changeEmail', loginInfo.username)
                    setModalState({
                        display: 'failed',
                        message:
                            'Failed to update Email Address. Please try again.'
                    })
                }
            }
        )
    }
    // Reset state whenever isVisible is toggled
    useEffect(() => {
        setModalState({ display: 'main', message: '' })
        setNewEmail(currentEmail)
        // I only want this to fire when isVisible is toggled, not if currentEmail changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])
    return (
        <>
            {modalState.display !== 'success' &&
                modalState.display !== 'failed' && (
                    <ModalWindow
                        open={isVisible}
                        title="Edit Email"
                        actionApply={changeEmailHandler}
                        actionApplyDisabled={
                            isLoading || currentEmail === newEmail
                        }
                        textApply={
                            isLoading ? 'Changing Email...' : 'Change Email'
                        }
                        actionCancel={closeModal}
                    >
                        <TextField
                            label="Email Address"
                            value={newEmail}
                            fullWidth
                            type="email"
                            onChange={(e) => setNewEmail(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    if (!isLoading) {
                                        changeEmailHandler()
                                    }
                                }
                            }}
                        />
                    </ModalWindow>
                )}
            {modalState.display === 'success' && (
                <ResultModal
                    open={isVisible}
                    title="Success"
                    actionCancel={closeModal}
                    iconType="success"
                    message={modalState.message}
                    copyButton
                />
            )}
            {modalState.display === 'failed' && (
                <ResultModal
                    open={isVisible}
                    title="Error"
                    actionCancel={closeModal}
                    iconType="error"
                    message={modalState.message}
                />
            )}
        </>
    )
}

export default ChangeEmailModal

ChangeEmailModal.propTypes = {
    /** Determines whether or not to show the modal */
    isVisible: PropTypes.bool.isRequired,
    /** Function to set isVisible to false. Usually from the useModalState hook */
    closeModal: PropTypes.func.isRequired,
    /** The username of the user we are changing the email for */
    username: PropTypes.string,
    /** Current Email of the user in case it just needs to be tweaked and not completely retyped */
    currentEmail: PropTypes.string,
    /** Function to refetch fresh data after a successful mutation */
    refetch: PropTypes.func.isRequired
}
