const users = {
    getSelf: () => ({
        path: '/users/',
        method: 'GET'
    }),
    getUser: ({ username }) => ({
        path: `/users/${username}`,
        method: 'GET'
    }),
    searchUser: ({ name }) => ({
        path: `/users/search/${name}`,
        method: 'GET'
    }),
    createUser: ({
        dealergroup,
        username,
        email,
        firstname,
        lastname,
        password
    }) => ({
        path: '/users/',
        method: 'POST',
        body: { dealergroup, username, email, firstname, lastname, password }
    }),
    deleteUser: ({ username }) => ({
        path: `/users/${username}`,
        method: 'DELETE',
        body: {}
    }),
    addUserToSecurityGroup: ({ username, cid }) => ({
        path: `/users/${username}/dealership/${cid}`,
        method: 'PUT',
        body: {}
    }),
    removeUserFromSecurityGroup: ({ username, cid }) => ({
        path: `/users/${username}/dealership/${cid}`,
        method: 'DELETE',
        body: {}
    }),
    enableUser: ({ username }) => ({
        path: `/users/${username}/enabled`,
        method: 'PUT',
        body: {}
    }),
    unlockUser: ({ username }) => ({
        path: `/users/${username}/lock`,
        method: 'DELETE',
        body: {}
    }),
    setEmail: ({ username, email }) => ({
        path: `/users/${username}/email`,
        method: 'PUT',
        body: { email }
    }),
    setPassword: ({ username, password }) => ({
        path: `/users/${username}/password`,
        method: 'PUT',
        body: { password }
    }),
    setPasswordSelf: ({ password }) => ({
        path: '/users/password',
        method: 'PUT',
        body: { password }
    }),
    grantAccountManager: ({ username }) => ({
        path: `/users/${username}/admin`,
        method: 'PUT',
        body: {}
    }),
    revokeAccountManager: ({ username }) => ({
        path: `/users/${username}/admin`,
        method: 'DELETE',
        body: {}
    })
}

export default users
