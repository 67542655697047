import styled from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import PropTypes from 'prop-types'
import {
    Root,
    getHeader,
    getContent,
    getDrawerSidebar,
    getSidebarContent,
    getSidebarTrigger,
    getMuiTreasuryScheme
} from '@mui-treasury/layout'

// Mui Treasury Components
// https://mui-treasury.com/layout/core-concept/
const Header = getHeader(styled)
const Content = getContent(styled)
const DrawerSidebar = getDrawerSidebar(styled)
const SidebarContent = getSidebarContent(styled)
const SidebarTrigger = getSidebarTrigger(styled)
// Using MUITreasury scheme since it suits our needs, no need to reinvent the wheel.
// https://mui-treasury.com/layout/presets/mui-treasury/
const standardScheme = getMuiTreasuryScheme()

const DashboardLayout = ({ children }) => {
    return (
        <Root scheme={standardScheme} themeProviderOmitted={true}>
            <CssBaseline />
            <Header color="primary" elevation={1}>
                <Toolbar>
                    <SidebarTrigger sidebarId="primarySidebar" />
                    <Navbar />
                </Toolbar>
            </Header>
            <DrawerSidebar
                sidebarId="primarySidebar"
                PaperProps={{
                    square: true
                }}
            >
                <SidebarContent>
                    <Sidebar />
                </SidebarContent>
            </DrawerSidebar>
            <Content>{children}</Content>
        </Root>
    )
}

export default DashboardLayout

DashboardLayout.propTypes = {
    children: PropTypes.node
}
