import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LoginContext } from './LoginContext'

// ReactQuery Client to manage queries/mutations
const queryClient = new QueryClient()

const ReactQueryClientProvider = ({ children }) => {
    // Get the function to refresh the login session with each successful query/mutation
    const { refreshIdle } = useContext(LoginContext)
    // Set the default when the component is mounted
    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                onSettled: (data, error) => {
                    // We need data as an argument even though we don't use it.
                    // If single argument, then it is passed either data OR error
                    if (!error) {
                        refreshIdle()
                    }
                }
            },
            mutations: {
                onSettled: (data, error) => {
                    if (!error) {
                        refreshIdle()
                    }
                }
            }
        })
        // I only want this to run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}

ReactQueryClientProvider.propTypes = {
    children: PropTypes.node
}

export default ReactQueryClientProvider
