import { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'

// When a user logs into the API, their token is valid for 1 hour.
// When the user hits an endpoint, it extends that duration by 1 hour.
// The API prevents inifinite session extension by placing a hard limit (24 hours)
const skew = 60000 // 1 minute skew (in milliseconds)
const hour = 3600000 // 1 hour (in milliseconds)
const sessionDuration = hour * 24 - skew
const idleDuration = hour - skew

// Holds bearer token. If no token exists, user is not logged in, to log out simply clear token.
// Also keep track of expirations to ensure we're not making API calls with an expired token.
// We also track user data (their display name, whether they are account manager, and what dealergroup they belong to) to determine what is displayed
const initialLoginInfo = {
    displayName: '', // Display friendly name that is displayed on the Navbar component
    accountManager: false, // Determines actions displayed on User page
    dealergroup: '', // If user is an account manager, allows fetching of data for Dealergroup users and companies
    username: '', // Used for checking if a user navigates to their own user page when clicking their name in list of users
    token: '', // Token to be included in Authorization Header as Bearer token when making requests to API
    sessionExpiration: 0, // Maximum life of the session (determined by API when logging in)
    idleExpiration: 0, // Idle timer, if API calls are made it refreshes the idleExpiration, cannot extend beyond sessionExpiration
    message: '' // Message displayed to user on Login page, useful for informing the user if they were logged out automatically from being idle
}

// Context
export const LoginContext = createContext()

// Provider
export const LoginProvider = ({ children }) => {
    // State
    const [loginInfo, setLoginInfo] = useState(initialLoginInfo)
    // Logging in, save token and username, set expirations, and clear message and displayname
    const initializeLoginInfo = (token, username) => {
        let time = Date.now()
        setLoginInfo({
            ...initialLoginInfo,
            token: token,
            sessionExpiration: time + sessionDuration,
            idleExpiration: time + idleDuration,
            username
        })
    }
    // When the user vists the Landing Page, we retrieve their DisplayName, Account Manager status, and which dealergroup they belong to.
    const setUserData = (displayName, accountManager, dealergroup) => {
        setLoginInfo((prevLoginInfo) => ({
            ...prevLoginInfo,
            displayName,
            accountManager,
            dealergroup
        }))
    }
    // Refresh the idleExpiration when an API call is made
    const refreshIdle = () => {
        // Extend idle expiration
        setLoginInfo((prevLoginInfo) => {
            // Ensure a token exists before extending duration
            if (prevLoginInfo.token === '') {
                return prevLoginInfo
            }
            // Update idleExpiration
            prevLoginInfo.idleExpiration = Date.now() + idleDuration
            return prevLoginInfo
        })
    }
    // When the user calls logout, reset loginInfo with an optional message informing the
    // user why (intentional logout, or expired session)
    const clearLoginInfo = (message) => {
        // If message is undefined just set it to an empty string
        if (!message) {
            message = ''
        }
        setLoginInfo({
            ...initialLoginInfo,
            message: message
        })
    }
    // Clear token when session expires
    useEffect(() => {
        // Run every 15 seconds
        const interval = setInterval(() => {
            // Ensure expiations are set before calculating
            if (
                loginInfo.idleExpiration === 0 ||
                loginInfo.sessionExpiration === 0
            ) {
                return
            }
            // Determine if our session is expired
            let time = Date.now()
            if (time > loginInfo.idleExpiration) {
                // Idle Timeout, clear token and inform user of timeout
                clearLoginInfo('Your session timed out due to inactivity.')
            }
            if (time > loginInfo.sessionExpiration) {
                // Session Expired, clear token and inform user of timeout
                clearLoginInfo('Your session has expired.')
            }
        }, 15000)
        // Clean up on unmount
        return () => clearInterval(interval)
    })
    return (
        <LoginContext.Provider
            value={{
                loginInfo,
                setLoginInfo,
                initializeLoginInfo,
                setUserData,
                refreshIdle,
                clearLoginInfo
            }}
        >
            {children}
        </LoginContext.Provider>
    )
}

LoginProvider.propTypes = {
    children: PropTypes.node
}
